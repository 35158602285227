import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  Text,
  Stack,
} from "@chakra-ui/react";
import React from "react";
import PageTitle from "../general/components/PageTitle";
import {
  faClipboardList,
  faMoneyBillTransfer,
} from "@fortawesome/free-solid-svg-icons";
import { colors } from "../Constants";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  createPayroll,
  getPayrollForTeacher,
  getPayrollsForTeacher,
} from "../services/payrollService";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

function SinglePayrollT({ hasLayout = true }) {
  const { id } = useParams();

  const { token } = useSelector((x) => x.security) || {};
  const { data: resp, status } = useQuery(["teacher-payroll", id], () =>
    getPayrollForTeacher(id, token)
  );

  const formatDate = (dateString, dateFormat) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return `${t("Invalid Date")}`;
    }
    return format(date, dateFormat);
  };

  const { t } = useTranslation("global");

  return (
    <Flex>
      {hasLayout && (
        <Box display={["none", "none", "block", "block"]} width="25%"></Box>
      )}
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <Box>
          <PageTitle
            fontSize={{ base: "1,2rem", sm: "2rem", md: "2.25rem" }}
            status={"success"}
            title={t("Payroll Details")}
            icon={faMoneyBillTransfer}
          />
        </Box>
        <Flex
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          w={["95%", "95%", "90%", "80%", "70%"]}
          margin="8px auto"
          p="20px 0"
          gap="24px"
          bg="#EAF5F4"
        >
          <FormControl w="90%">
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Period Start Date")}:
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
              <Input
                fontSize={["10px", "12px", "12px", "14px", "16px"]}
                value={formatDate(resp?.data?.periodStartDate, "dd/MM/yyyy")}
                bg="white"
                borderRadius="4px"
                p="0 12px"
                isDisabled
              />
            </Skeleton>
          </FormControl>

          <FormControl w="90%">
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Period End Date")}:
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
              <Input
                fontSize={["10px", "12px", "12px", "14px", "16px"]}
                value={formatDate(resp?.data?.periodEndDate, "dd/MM/yyyy")}
                bg="white"
                borderRadius="4px"
                p="0 12px"
                isDisabled
              />
            </Skeleton>
          </FormControl>
          <FormControl w="90%">
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Number of hours worked")}
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
              <Input
                fontSize={["10px", "12px", "12px", "14px", "16px"]}
                name="hoursWorked"
                value={resp?.data?.hoursWorked}
                bg="white"
                borderRadius="4px"
                p="0 12px"
                type="number"
                isDisabled
                //   bgColor="red"
              />
            </Skeleton>
          </FormControl>
          <FormControl w="90%">
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Salary")}
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
              <Input
                fontSize={["10px", "12px", "12px", "14px", "16px"]}
                name="salary"
                value={resp?.data?.salary}
                bg="white"
                borderRadius="4px"
                p="0 12px"
                type="number"
                isDisabled
              />
            </Skeleton>
          </FormControl>
          <FormControl w="90%">
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Currency")}
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
              <Input
                fontSize={["10px", "12px", "12px", "14px", "16px"]}
                p="0 12px"
                value={resp?.data?.currency}
                bg="white"
                borderRadius="4px"
                isDisabled
              />
            </Skeleton>
          </FormControl>

          <FormControl w="90%">
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Description")}
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
              <Input
                fontSize={["10px", "12px", "12px", "14px", "16px"]}
                value={resp?.data?.description}
                bg={resp?.data?.description ? "white" : "white"}
                borderRadius="4px"
                p="0 12px"
                type="text"
                placeholder={t("Description")}
                _placeholder={{ color: "#666666" }}
                isDisabled
              />
            </Skeleton>
          </FormControl>

          <FormControl w="90%">
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Status")}
            </FormLabel>
            <RadioGroup isDisabled value={resp?.data?.isAccepted ?? 0}>
              <Stack>
                <Skeleton isLoaded={status == "success"}>
                  <Radio colorScheme={"orange"} value={0}>
                    <Text color={"orange"} fontWeight={"bold"}>
                      {t("Pending")}
                    </Text>
                  </Radio>
                </Skeleton>

                <Skeleton isLoaded={status == "success"}>
                  <Radio colorScheme="red" value={false}>
                    <Text color={"red"} fontWeight={"bold"}>
                      {t("Rejected")}
                    </Text>
                  </Radio>
                </Skeleton>

                <Skeleton isLoaded={status == "success"}>
                  <Radio colorScheme="green" value={true}>
                    <Text color={"green"} fontWeight={"bold"}>
                      {t("Accepted")}
                    </Text>
                  </Radio>
                </Skeleton>
              </Stack>
            </RadioGroup>
          </FormControl>
        </Flex>
      </Container>
    </Flex>
  );
}

export default SinglePayrollT;
