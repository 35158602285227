import React, { useEffect, useState } from "react";
import { Box, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import Ellipse from "./Ellipse";
import { colors } from "../../Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

function GeneralCalendar({ fName, position, lastName, src }) {
  const daysOfWeek = ["M", "T", "W", "T", "F", "S", "S"];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [days, setDays] = useState([]);
  const [edgeDate, setEdgeDate] = useState(null);
  const [currentMonthAndYear, setCurrentMonthAndYear] = useState({
    month: null,
    year: null,
  });

  const _isToday = (date, day) => {
    const todayDate = new Date();
    return (
      date.getFullYear() == todayDate.getFullYear() &&
      date.getMonth() == todayDate.getMonth() &&
      todayDate.getDate() == day
    );
  };
  const calculateWeek = (date) => {
    const today1 = date == null ? new Date() : new Date(date);
    const today2 = date == null ? new Date() : new Date(date);

    let first =
      today1.getDate() - (today1.getDay() == 0 ? 7 : today1.getDay()) + 1;
    today1.setDate(first);
    let firstDayOfTheWeek = today1.getDate();

    let last = first + 6;
    today2.setDate(last);
    let lastDayOfTheWeek = today2.getDate();

    setCurrentMonthAndYear({
      month: months[today2.getMonth()],
      year: today2.getFullYear(),
    });

    if (today1.getMonth() == today2.getMonth()) {
      let _days = [];
      for (let i = firstDayOfTheWeek; i <= lastDayOfTheWeek; i++) {
        _days.push({ date: i, isToday: _isToday(today1, i) });
      }
      setDays(_days);
    } else {
      let _days = [];
      for (
        let i = firstDayOfTheWeek;
        i <= new Date(today1.getFullYear(), today1.getMonth() + 1, 0).getDate();
        i++
      ) {
        _days.push({ date: i, isToday: _isToday(today1, i) });
      }
      for (let i = 1; i <= lastDayOfTheWeek; i++) {
        _days.push({ date: i, isToday: _isToday(today2, i) });
      }
      setDays(_days);
    }
    setEdgeDate(today2);
  };

  const calculateNextWeek = () => {
    edgeDate.setDate(edgeDate.getDate() + 1);
    calculateWeek(edgeDate);
  };

  const calculatePrevWeek = () => {
    edgeDate.setDate(edgeDate.getDate() - 7);
    calculateWeek(edgeDate);
  };

  useEffect(() => {
    calculateWeek();
  }, []);

  return (
    <Flex
      w={`calc((100% / 3 ) - 12px)`}
      sx={{
        "@media (max-width: 992px)": {
          w: "calc((100% - 69%) )",
        },
        "@media (max-width: 768px)": {
          w: "calc((100%  - 12px) )",
        },
      }}
      flexDir="column"
      gap="20px"
    >
      <Box>
        <Ellipse src={src} />

        <Text
          mt={{ base: "12px" }}
          textAlign="center"
          fontWeight="600"
          sx={{
            "@media (max-width: 768px)": {
              color: "white",
            },
          }}
        >
          {fName}
        </Text>
        <Text
          textAlign="center"
          lineHeight="28px"
          fontWeight="600"
          sx={{
            "@media (max-width: 768px)": {
              color: "white",
            },
          }}
        >
          {lastName}
        </Text>
        <Text
          mt={{ base: "10px", md: "18px" }}
          textAlign="center"
          sx={{
            "@media (max-width: 768px)": {
              color: "white",
            },
          }}
          color="gray"
        >
          {position}
        </Text>
      </Box>
      <Box
        display={["none", "none", "none", "block", "block"]}
        w="100%"
        mt="12px"
        bg={colors.primary}
        borderRadius="12px"
        padding="20px 0px"
        color="white"
      >
        <HStack
          fontSize={["18px", "18px", "18px", "16px", "18px"]}
          fontWeight="700"
          alignItems="center"
          justifyContent="space-between"
          paddingX="20px"
        >
          <FontAwesomeIcon
            cursor={"pointer"}
            icon={faChevronLeft}
            onClick={calculatePrevWeek}
          />
          <Text userSelect={"none"}>
            {currentMonthAndYear.month} {currentMonthAndYear.year}
          </Text>
          <FontAwesomeIcon
            cursor={"pointer"}
            icon={faChevronRight}
            onClick={calculateNextWeek}
          />
        </HStack>

        <HStack w="100%" m="18px 0" px="8px" justifyContent="space-between">
          {daysOfWeek.map((day, index) => (
            <Text
              userSelect={"none"}
              w="30px"
              textAlign="center"
              key={index}
              fontSize={["14px", "14px", "14px", "14px", "16px"]}
              fontWeight="500"
            >
              {day}
            </Text>
          ))}
        </HStack>
        <HStack w="100%" m="18px 0 0" px="8px" justifyContent="space-between">
          {days.map((x, index) => (
            <Text
              userSelect={"none"}
              border="1px solid transparent"
              borderRadius="100%"
              bg={x.isToday ? "red" : undefined}
              borderColor={x.isToday ? "red" : undefined}
              boxSize={["30px", "30px", "26px", "26px", "30px"]}
              textAlign="center"
              key={index}
              fontSize={["14px", "10px", "14px", "14px", "16px"]}
              fontWeight="500"
            >
              {x.date}
            </Text>
          ))}
        </HStack>
      </Box>
    </Flex>
  );
}

export default GeneralCalendar;
