import * as Yup from "yup";

export function getGradeQuestionSchema(maxGrade, t) {
  return Yup.object().shape({
    grade: Yup.number()
      .min(0, t("Grade cannot be less than 0"))
      .max(maxGrade, t(`Grade cannot be more than ${maxGrade}`))
      .required(t("Grade is required")),
  });
}
