import {
  Divider,
  Flex,
  HStack,
  List,
  ListItem,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { faClipboardList, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { colors } from "../../Constants";
import { getGroupEventsPromise } from "../../services/eventService";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

function GroupEvents() {
  const { t } = useTranslation("global");

  const { token, selectedGroupId } = useSelector((x) => x.security);

  const { data: resp, status } = useQuery(
    ["group-events", selectedGroupId, token],
    () => getGroupEventsPromise(selectedGroupId, token)
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return `${t("Invalid Date")}`;
    }
    return format(date, "MMM do yyy, HH:mm");
  };

  return (
    <>
      {status == "loading" && (
        <Flex
          minh="50vh"
          mb="32px"
          padding="18px 40px"
          gap="24px"
          border="4px solid "
          borderColor={colors.primary}
          w={`calc((100% / 3 * 2) - 16px)`}
          sx={{
            "@media (max-width: 900px)": {
              w: "90%",
              padding: "18px 20px",
            },
            "@media (max-width: 768px)": {
              w: "100%",
            },
            "@media (max-width: 400px)": {
              // w: "90%",
              padding: "18px 8px",
            },
          }}
          borderRadius="12px"
        >
          <List w="100%">
            <ListItem padding="20px 0px">
              <Skeleton>
                <Flex height={"50px"} gap="12px" alignItems="center"></Flex>
              </Skeleton>
            </ListItem>
            <Divider mb="16px" borderColor={colors.gray} borderWidth="1px" />
            <ListItem padding="20px 0px">
              <Skeleton>
                <Flex height={"50px"} gap="12px" alignItems="center"></Flex>
              </Skeleton>
            </ListItem>
            <Divider mb="16px" borderColor={colors.gray} borderWidth="1px" />
            <ListItem padding="20px 0px">
              <Skeleton>
                <Flex height={"50px"} gap="12px" alignItems="center"></Flex>
              </Skeleton>
            </ListItem>
            <Divider mb="16px" borderColor={colors.gray} borderWidth="1px" />
          </List>
        </Flex>
      )}

      {status == "success" &&
        (resp.data?.length > 0 ? (
          <Flex
            minh="50vh"
            mb="32px"
            padding="18px 40px"
            gap="24px"
            border="4px solid "
            borderColor={colors.primary}
            w={`calc((100% / 3 * 2) - 16px)`}
            borderRadius="12px"
            sx={{
              "@media (max-width: 900px)": {
                w: "90%",
                padding: "18px 20px",
              },
              "@media (max-width: 768px)": {
                w: "100%",
              },
              "@media (max-width: 400px)": {
                // w: "90%",
                padding: "18px 8px",
              },
            }}
          >
            <List w="100%">
              {resp?.data?.map((item) => (
                <>
                  <ListItem padding="20px 0px">
                    <Flex
                      pr="8px"
                      gap={"12px"}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <HStack
                        fontSize={{
                          base: "10px",
                          sm: "14px",
                          md: "16px",
                        }}
                        gap="12px"
                      >
                        {" "}
                        <FontAwesomeIcon
                          color={colors.primary}
                          icon={faClipboardList}
                        />
                        <Text fontWeight="700">{item.name}</Text>
                      </HStack>
                      {item.deadline !== null ? (
                        <Text
                          fontSize={{
                            base: "10px",
                            sm: "12px",
                            md: "16px",
                          }}
                        >
                          {t("Deadline")}:{" "}
                          <Text fontWeight="600" as={"span"}>
                            {formatDate(item.deadline)}
                          </Text>
                        </Text>
                      ) : (
                        <Text>{t("No deadline")}</Text>
                      )}
                    </Flex>
                  </ListItem>
                  {resp?.data?.length > 1 && (
                    <Divider
                      mb="16px"
                      borderColor={colors.gray}
                      borderWidth="1px"
                    />
                  )}
                </>
              ))}
            </List>
          </Flex>
        ) : (
          <Flex
            mb="32px"
            padding="18px 40px"
            gap="24px"
            border="4px solid "
            borderColor={colors.primary}
            w={`calc((100% / 3 * 2) - 16px)`}
            borderRadius="12px"
            sx={{
              "@media (max-width: 900px)": {
                w: "90%",
                padding: "18px 20px",
              },
              "@media (max-width: 768px)": {
                w: "100%",
              },
              "@media (max-width: 400px)": {
                // w: "90%",
                padding: "18px 8px",
              },
            }}
            h={["25vh", "30vh", "40vh", "40vh"]}
            justifyContent={"center"}
            alignItems="center"
          >
            <Text
              fontSize={["18px", "28px", "28px", "28px", "32px"]}
              fontWeight="600"
              textAlign="center"
            >
              {t("No upcomoing Events")}
            </Text>
            <FontAwesomeIcon
              fontSize="22px"
              color={colors.primary}
              icon={faClock}
            />
          </Flex>
        ))}
    </>
  );
}

export default GroupEvents;
