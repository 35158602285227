import {
  Box,
  Center,
  Container,
  Flex,
  HStack,
  Input,
  Skeleton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import PageTitle from "../general/components/PageTitle";
import {
  faCopy,
  faSpinner,
  faTable,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import { colors } from "../Constants";
import { useSelector } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  assignmentEvaluation,
  getAssignmentGradesForTeacherPromise,
} from "../services/assignmentService";
import ProfilePicture from "../general/components/ProfilePicture";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getExamGradesForTeacherPromise,
  gradeExamSubmissionPromise,
} from "../services/examService";
import { useTranslation } from "react-i18next";

function GradesT() {
  const { token, selectedGroupId } = useSelector((x) => x.security);
  const queryClient = useQueryClient();
  const [tabIndex, setTabIndex] = useState(0);

  const { data: respAssignmentGrades, status: statusAssignmentGrades } =
    useQuery(["teacher-assignment-grades", selectedGroupId, token], () =>
      getAssignmentGradesForTeacherPromise(token, selectedGroupId)
    );

  const { data: respExamGrades, status: statusExamGrades } = useQuery(
    ["teacher-exam-grades", selectedGroupId, token],
    () => getExamGradesForTeacherPromise(token, selectedGroupId)
  );

  const { mutate: mutateGradeAssignment } = useMutation(
    (data) => assignmentEvaluation(data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["teacher-assignment-grades"],
        });
      },
    }
  );

  const { mutate: mutateGradeExam } = useMutation(
    ({ examSubmissionId, data }) =>
      gradeExamSubmissionPromise(examSubmissionId, data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["teacher-exam-grades"] });
      },
    }
  );

  const toast = useToast();
  const gradeAssignment = (assignmentSubmissionId, grade) => {
    grade = parseFloat(grade);
    if (isNaN(grade) || grade > 100 || grade < 0) {
      toast({
        position: "top-right",
        title: `${t("Error")}`,
        description: `${t("Grade should be between 0 and 100!")}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    mutateGradeAssignment({ assignmentSubmissionId, grade });
  };

  const gradeExam = (examSubmissionId, grade) => {
    grade = parseFloat(grade);
    grade = parseFloat(grade);
    if (isNaN(grade) || grade > 100 || grade < 0) {
      toast({
        position: "top-right",
        title: `${t("Error")}`,
        description: `${t("Grade should be between 0 and 100!")}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    mutateGradeExam({ examSubmissionId, data: { grade } });
  };

  const { t } = useTranslation("global");

  return (
    <Flex>
      <Box display={["none", "none", "block", "block"]} width="25%"></Box>
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <PageTitle title={t("Grades")} status={"success"} icon={faTable} />
        <Tabs variant={"unstyled"} onChange={(i) => setTabIndex(i)}>
          <HStack gap="20px">
            <TabList w="100%">
              <Tab p="0">
                <HStack
                  transition=".3s"
                  mt="3rem"
                  borderRadius="6px"
                  textAlign="center"
                  alignItems="center"
                  justifyContent="center"
                  gap="12px"
                  // w="180px"
                  p="12px"
                  height="48px"
                  fontWeight="500"
                  fontSize={["10px", "14px", "12px", "14px", "16px", "18px"]}
                  bg={!tabIndex && "#EAF5F4"}
                  _hover={tabIndex && { bg: "#EAF5F4", cursor: "pointer" }}
                  cursor="default"
                >
                  <FontAwesomeIcon icon={faCopy} />
                  <Text>{t("Assignments")}</Text>
                </HStack>
              </Tab>
              <Tab p="0">
                <HStack
                  transition=".3s"
                  _hover={{ bg: "#EAF5F4", cursor: "pointer" }}
                  mt="3rem"
                  borderRadius="6px"
                  textAlign="center"
                  alignItems="center"
                  justifyContent="center"
                  gap="12px"
                  bg={tabIndex && "#EAF5F4"}
                  // width="180px"

                  p="12px"
                  height="48px"
                  fontWeight="500"
                  fontSize={["10px", "14px", "12px", "14px", "16px", "18px"]}
                >
                  <FontAwesomeIcon icon={faCopy} />
                  <Text cursor="pointer">{t("Exams")}</Text>
                </HStack>
              </Tab>
            </TabList>
          </HStack>

          <TabPanels>
            <TabPanel p="0">
              <Flex m="20px 0" justifyContent="space-between">
                <Box
                  alignItems="start"
                  padding="20px 0"
                  h="450px"
                  w={["70%", "50%", "30%", "30%", "25%"]}
                  pr={["4px", "4px", "4px", "20px"]}
                  border="2px solid transparent"
                  borderRightColor={colors.primary}
                >
                  <Flex
                    flexDir="column"
                    gap={["12px", "10px", "12px", "10px"]}
                    mt={[
                      "3.3rem",
                      "4.160rem",
                      "4.160rem",
                      "4.160rem",
                      "4.160rem",
                    ]}
                  >
                    {respAssignmentGrades?.data?.students?.map((student) => (
                      <Skeleton isLoaded={statusAssignmentGrades == "success"}>
                        <Flex
                          gap={["0", "0", "0", "4px"]}
                          h="63px"
                          alignItems="center"
                        >
                          <Flex>
                            <ProfilePicture url={student.profilePictureKey} />
                          </Flex>
                          <Text
                            fontSize={[
                              "10px",
                              "12px",
                              "10px",
                              "13px",
                              "14px",
                              "16px",
                            ]}
                            fontWeight="600"
                            textAlign={"start"}
                          >
                            {`${student.name} ${student.surname} (${student.username})`}
                          </Text>
                        </Flex>
                      </Skeleton>
                    ))}
                  </Flex>
                </Box>

                <Flex
                  w={["100%", "100%", "70%", "75%"]}
                  padding="0 8px 0 10px "
                  gap="12px"
                  overflowX="auto"
                  sx={{
                    "::-webkit-scrollbar-thumb": {
                      borderRadius: "12px",
                      background: colors.primary,
                    },
                    "::-webkit-scrollbar": {
                      height: "2px",
                    },
                    "::-webkit-scrollbar-track": {
                      width: "6px",
                    },
                  }}
                >
                  {respAssignmentGrades?.data?.assignments?.map((item) => (
                    <Flex
                      flexDir="column"
                      gap="24px"
                      minW={["100%", "49%", "49%", "32.2%"]}
                    >
                      <Skeleton isLoaded={statusAssignmentGrades == "success"}>
                        <Flex
                          justifyContent="space-between"
                          sx={{
                            boxShadow:
                              "-8px 7px 6px 0px rgba(169,151,151,0.75)",
                          }}
                          color="white"
                          bg={colors.primary}
                          borderRadius="6px"
                          padding="12px"
                          h={["50px", "63px", "63px", "63px", "63px"]}
                          w="100%"
                          display="flex"
                          alignItems="center"
                        >
                          <Text
                            fontSize={[
                              "12px",
                              "18px",
                              "16px",
                              "16px",
                              "17px",
                              "20px",
                            ]}
                            fontWeight="500"
                          >
                            {item.name}
                          </Text>
                        </Flex>
                      </Skeleton>

                      <Flex flexDir="column" gap="12px">
                        {item?.submissions?.map((record, index) => (
                          <Skeleton
                            isLoaded={statusAssignmentGrades == "success"}
                          >
                            <React.Fragment key={record?.id || index}>
                              {record === null ? (
                                <Center
                                  bg="white"
                                  color="black"
                                  borderRadius="6px"
                                  padding="12px"
                                  h="63px"
                                  w="100%"
                                  border={`1px solid ${colors.primary}`}
                                  fontSize="12px"
                                  fontWeight="500"
                                >
                                  {t(
                                    "The student was not enrolled in the group"
                                  )}
                                </Center>
                              ) : record.grade == null ? (
                                record.submissionTime == null ? (
                                  <Center
                                    bg="white"
                                    color="black"
                                    borderRadius="6px"
                                    padding="12px"
                                    h="63px"
                                    w="100%"
                                    border={`1px solid ${colors.primary}`}
                                    fontSize="12px"
                                    fontWeight="500"
                                  >
                                    {t("Has not submitted yet")}
                                  </Center>
                                ) : (
                                  <Input
                                    defaultValue={record.grade}
                                    onBlur={(e) =>
                                      gradeAssignment(record.id, e.target.value)
                                    }
                                    bg="white"
                                    placeholder={t("Grade")}
                                    color="black"
                                    borderRadius="6px"
                                    padding="12px"
                                    h="63px"
                                    w="100%"
                                    border={`1px solid ${colors.primary}`}
                                    fontWeight="500"
                                  />
                                )
                              ) : (
                                <Input
                                  defaultValue={record.grade}
                                  onBlur={(e) =>
                                    gradeAssignment(record.id, e.target.value)
                                  }
                                  bg="white"
                                  color="black"
                                  borderRadius="6px"
                                  padding="12px"
                                  h="63px"
                                  w="100%"
                                  border={`1px solid ${colors.primary}`}
                                  fontWeight="500"
                                />
                              )}
                            </React.Fragment>
                          </Skeleton>
                        ))}
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            </TabPanel>

            <TabPanel p="0">
              <Flex m="20px 0" justifyContent="space-between">
                <Box
                  alignItems="start"
                  padding="20px 0"
                  h="450px"
                  w={["70%", "50%", "30%", "30%", "25%"]}
                  pr={["4px", "4px", "4px", "20px"]}
                  border="2px solid transparent"
                  borderRightColor={colors.primary}
                >
                  <Flex
                    flexDir="column"
                    // gap="12px"
                    gap={["12px", "10px", "12px", "10px"]}
                    mt={[
                      "3.3rem",
                      "4.160rem",
                      "4.160rem",
                      "4.160rem",
                      "4.160rem",
                    ]}
                  >
                    {respExamGrades?.data?.students?.map((student) => (
                      <Skeleton isLoaded={statusAssignmentGrades == "success"}>
                        <Flex
                          gap={["0", "0", "0", "4px"]}
                          h="63px"
                          alignItems="center"
                        >
                          <Flex>
                            <ProfilePicture url={student.profilePictureKey} />
                          </Flex>
                          <Text
                            fontSize={[
                              "10px",
                              "12px",
                              "10px",
                              "13px",
                              "14px",
                              "16px",
                            ]}
                            fontWeight="600"
                            textAlign={"start"}
                          >
                            {`${student.name} ${student.surname} (${student.username})`}
                          </Text>
                        </Flex>
                      </Skeleton>
                    ))}
                  </Flex>
                </Box>

                <Flex
                  w={["100%", "100%", "70%", "75%"]}
                  padding="0 8px 0 10px "
                  gap="12px"
                  overflowX="auto"
                  sx={{
                    "::-webkit-scrollbar-thumb": {
                      borderRadius: "12px",
                      background: colors.primary,
                    },
                    "::-webkit-scrollbar": {
                      height: "2px",
                    },
                    "::-webkit-scrollbar-track": {
                      width: "6px",
                    },
                  }}
                >
                  {respExamGrades?.data?.exams?.map((item) => (
                    <Flex
                      flexDir="column"
                      gap="24px"
                      minW={["100%", "49%", "49%", "32.2%"]}
                    >
                      <Skeleton isLoaded={statusExamGrades == "success"}>
                        <Flex
                          justifyContent="space-between"
                          sx={{
                            boxShadow:
                              "-8px 7px 6px 0px rgba(169,151,151,0.75)",
                          }}
                          color="white"
                          bg={colors.primary}
                          borderRadius="6px"
                          padding="12px"
                          h={["50px", "63px", "63px", "63px", "63px"]}
                          w="100%"
                          display="flex"
                          alignItems="center"
                        >
                          <Text
                            fontSize={[
                              "12px",
                              "18px",
                              "16px",
                              "16px",
                              "17px",
                              "20px",
                            ]}
                            fontWeight="500"
                          >
                            {item.name}
                          </Text>
                        </Flex>
                      </Skeleton>

                      <Flex flexDir="column" gap="12px">
                        {item?.submissions?.map((record, index) => (
                          <Skeleton isLoaded={statusExamGrades == "success"}>
                            <React.Fragment key={record?.id || index}>
                              {record === null ? (
                                <Center
                                  bg="white"
                                  color="black"
                                  borderRadius="6px"
                                  padding="12px"
                                  h="63px"
                                  w="100%"
                                  border={`1px solid ${colors.primary}`}
                                  fontSize="12px"
                                  fontWeight="500"
                                >
                                  {"The student was not enrolled in the group"}
                                </Center>
                              ) : record.grade == null ? (
                                record.submissionTime == null ? (
                                  <Center
                                    bg="white"
                                    color="black"
                                    borderRadius="6px"
                                    padding="12px"
                                    h="63px"
                                    w="100%"
                                    border={`1px solid ${colors.primary}`}
                                    fontSize="12px"
                                    fontWeight="500"
                                  >
                                    {t("Has not submitted yet")}
                                  </Center>
                                ) : (
                                  <Input
                                    defaultValue={record.grade}
                                    onBlur={(e) =>
                                      gradeExam(record.id, e.target.value)
                                    }
                                    bg="white"
                                    placeholder={t("Grade")}
                                    color="black"
                                    borderRadius="6px"
                                    padding="12px"
                                    h="63px"
                                    w="100%"
                                    border={`1px solid ${colors.primary}`}
                                    fontWeight="500"
                                  />
                                )
                              ) : (
                                <Input
                                  defaultValue={record.grade}
                                  onBlur={(e) =>
                                    gradeExam(record.id, e.target.value)
                                  }
                                  bg="white"
                                  color="black"
                                  borderRadius="6px"
                                  padding="12px"
                                  h="63px"
                                  w="100%"
                                  border={`1px solid ${colors.primary}`}
                                  fontWeight="500"
                                />
                              )}
                            </React.Fragment>
                          </Skeleton>
                        ))}
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </Flex>
  );
}

export default GradesT;
