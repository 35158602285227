import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  HStack,
  List,
  ListItem,
  SimpleGrid,
  Skeleton,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";
import PageTitle from "../general/components/PageTitle";
import {
  faClipboardList,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { colors } from "../Constants";
import SearchBar from "../student/components/SearchBar";
import StudentpaymentModal from "./modal/StudentpaymentModal";
import { useSelector } from "react-redux";
import { getStudentsForPaymentPromise } from "../services/studentPaymentService";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import ProfilePicture from "../general/components/ProfilePicture";
import SimpleBar from "simplebar-react";

export default function StudentPayment() {
  const navigate = useNavigate();
  const [isLessThan610px] = useMediaQuery("(max-width: 610px)");
  const { token } = useSelector((x) => x.security) || {};
  const { data: resp, status } = useQuery(["payment-students", token], () =>
    getStudentsForPaymentPromise(token)
  );

  const _renderSoonBtn = () => {
    return (
      <Button
        pointerEvents="none"
        h="36px"
        w="120px"
        _hover="none"
        borderRadius="7px"
        color="white"
        bg="#DBD638"
        border="1px solid #DBD638 "
        padding="6px"
        fontSize="16px"
        fontWeight="400"
        cursor="auto"
      >
        {t("payment.paymentSoon")}
      </Button>
    );
  };

  const _renderDueBtn = () => {
    return (
      <Button
        pointerEvents="none"
        h="36px"
        w="120px"
        _hover="none"
        borderRadius="7px"
        color="white"
        bg="#F00000"
        border="1px solid #F00000 "
        padding="6px"
        fontSize="16px"
        fontWeight="400"
        cursor="auto"
      >
        {t("payment.paymentDue")}
      </Button>
    );
  };

  const _renderStatus = (datesArray) => {
    let isAtLeastOneDue = datesArray.some((x) => {
      let date1 = new Date(x).setHours(0, 0, 0, 0);
      let date2 = new Date().setHours(0, 0, 0, 0);
      return date2 >= date1;
    });
    if (isAtLeastOneDue) return _renderDueBtn();
    let isAtLeastOneSoon = datesArray.some((x) => {
      let date1 = new Date(x).setHours(0, 0, 0, 0);
      let date2 = new Date().setHours(0, 0, 0, 0);
      let dayDiff = Math.floor((date1 - date2) / (1000 * 60 * 60 * 24));
      return dayDiff > 0 && dayDiff <= 7;
    });
    if (isAtLeastOneSoon) return _renderSoonBtn();
  };

  const { t } = useTranslation("global");

  return (
    <Flex>
      <Box display={["none", "none", "block", "block"]} width="25%"></Box>
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <PageTitle
          status={"success"}
          title={t("sidebar.studentPayments")}
          icon={faCreditCard}
        />

        {status === "error" && (
          <Box textAlign="center">
            <Text fontWeight="600" fontSize="24px" color="red">
              {t("errMessages.message1")}
            </Text>
            <Text fontWeight="600" fontSize="24px" color="red">
              {t("errMessages.message1")}
            </Text>
          </Box>
        )}

        {status === "success" && resp?.data?.length === 0 && (
          <Text fontWeight="600" fontSize="24px" color="gray">
            {t("No upcoming payments at the moment.")}
          </Text>
        )}
        {resp?.data?.length !== 0 && (
          <Box mb="28px" w="100%">
            {isLessThan610px ? (
              <SimpleBar
                autoHide={false}
                style={{ maxHeight: "calc(100vh - 100px)" }}
              >
                <Box
                  sx={{
                    "@media (max-width: 610px)": {
                      minW: "600px",
                    },
                  }}
                >
                  {(status == "loading" || resp?.data?.length > 0) && (
                    <>
                      <SimpleGrid
                        m="1rem 0"
                        padding="14px"
                        bg="#EAF5F4"
                        fontSize="16px"
                        h="60px"
                        color={colors.primary}
                        columns={3}
                      >
                        <Text as="i">{t("table.name")}</Text>
                        <Text textAlign="center" as="i">
                          {t("table.username")}
                        </Text>{" "}
                        <Text textAlign="end" as="i">
                          {t("table.status")}
                        </Text>
                      </SimpleGrid>
                    </>
                  )}
                  <List mb="2rem" w="100%">
                    <>
                      {status == "loading" &&
                        Array.from({ length: 5 }).map(() => {
                          return (
                            <>
                              <Skeleton>
                                <ListItem
                                  fontSize="16px"
                                  padding="20px 14px"
                                ></ListItem>
                                <Divider
                                  w="100%"
                                  m="8px 0 16px"
                                  borderColor={colors.gray}
                                  borderWidth="1px"
                                />
                              </Skeleton>
                            </>
                          );
                        })}

                      {status == "success" &&
                        resp?.data?.map((x) => {
                          return (
                            <>
                              <ListItem
                                fontSize="16px"
                                fontWeight="500"
                                padding="0 14px"
                              >
                                <SimpleGrid
                                  alignItems={"center"}
                                  columns={3}
                                  justifyContent="space-between"
                                >
                                  <Text
                                    display={"flex"}
                                    alignItems={"center"}
                                    fontSize="16px"
                                    fontWeight="500"
                                    transition=".3s"
                                    _hover={{ color: "teal.400" }}
                                    onClick={() =>
                                      navigate(
                                        `/manager/student-payment/${x.studentId}`
                                      )
                                    }
                                    cursor="pointer"
                                  >
                                    <ProfilePicture url={x.profilePictureKey} />
                                    {`${x.name} ${x.surname}`}
                                  </Text>
                                  <Text
                                    textAlign="center"
                                    fontSize="16px"
                                    fontWeight="500"
                                  >
                                    {x.username}
                                  </Text>

                                  <Text textAlign="end">
                                    {_renderStatus(x.nextPaymentDates)}
                                  </Text>
                                </SimpleGrid>
                              </ListItem>
                              <Divider
                                w="100%"
                                m="8px 0 16px"
                                borderColor={colors.gray}
                                borderWidth="1px"
                              />
                            </>
                          );
                        })}
                    </>
                  </List>
                </Box>
              </SimpleBar>
            ) : (
              <Box>
                {(status == "loading" || resp?.data?.length > 0) && (
                  <>
                    <SimpleGrid
                      m="1rem 0"
                      padding="14px"
                      bg="#EAF5F4"
                      fontSize="16px"
                      h="60px"
                      color={colors.primary}
                      columns={3}
                    >
                      <Text as="i">{t("table.name")}</Text>
                      <Text textAlign="center" as="i">
                        {t("table.username")}
                      </Text>{" "}
                      <Text textAlign="end" as="i">
                        {t("table.status")}
                      </Text>
                    </SimpleGrid>
                  </>
                )}
                <List mb="2rem" w="100%">
                  <>
                    {status == "loading" &&
                      Array.from({ length: 5 }).map(() => {
                        return (
                          <>
                            <Skeleton>
                              <ListItem
                                fontSize="16px"
                                padding="20px 14px"
                              ></ListItem>
                              <Divider
                                w="100%"
                                m="8px 0 16px"
                                borderColor={colors.gray}
                                borderWidth="1px"
                              />
                            </Skeleton>
                          </>
                        );
                      })}

                    {status == "success" &&
                      resp?.data?.map((x) => {
                        return (
                          <>
                            <ListItem
                              fontSize="16px"
                              fontWeight="500"
                              padding="0 14px"
                            >
                              <SimpleGrid
                                alignItems={"center"}
                                columns={3}
                                justifyContent="space-between"
                              >
                                <Text
                                  display={"flex"}
                                  alignItems={"center"}
                                  fontSize="16px"
                                  fontWeight="500"
                                  transition=".3s"
                                  _hover={{ color: "teal.400" }}
                                  onClick={() =>
                                    navigate(
                                      `/manager/student-payment/${x.studentId}`
                                    )
                                  }
                                  cursor="pointer"
                                >
                                  <ProfilePicture url={x.profilePictureKey} />
                                  {`${x.name} ${x.surname}`}
                                </Text>
                                <Text
                                  textAlign="center"
                                  fontSize="16px"
                                  fontWeight="500"
                                >
                                  {x.username}
                                </Text>

                                <Text textAlign="end">
                                  {_renderStatus(x.nextPaymentDates)}
                                </Text>
                              </SimpleGrid>
                            </ListItem>
                            <Divider
                              w="100%"
                              m="8px 0 16px"
                              borderColor={colors.gray}
                              borderWidth="1px"
                            />
                          </>
                        );
                      })}
                  </>
                </List>
              </Box>
            )}
          </Box>
        )}
      </Container>
    </Flex>
  );
}
