import React, { useRef, useState } from "react";
import PageTitle from "../general/components/PageTitle";
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  Flex,
  HStack,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { colors } from "../Constants";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboardList,
  faCopy,
  faPaperclip,
  faPlus,
  faSpinner,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { format, isValid } from "date-fns";
import {
  getSingleAssignmentForStudent,
  submitAssignmentPromise,
  updateAssignmentSubmissionPromise,
} from "../services/assignmentService";
import MyJoditEditor from "../helpers/MyJoditEditor";
import { useFormik } from "formik";
import useMyJoditEditor from "../hooks/useMyJoditEditor";
import InnerHtml from "../helpers/InnerHtml";
import ProfilePicture from "../general/components/ProfilePicture";
import ReadOnlyFile from "../general/components/ReadOnlyFile";
import ExistingFile from "../general/components/ExistingFile";
import NewFile from "../general/components/NewFile";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

function SingleAssignment() {
  const { t } = useTranslation("global");
  const queryClient = useQueryClient();
  const { selectedGroupId, token } = useSelector((x) => x.security);
  const { id } = useParams();
  const ref = useRef();
  const [newFiles, setNewFiles] = useState([]);
  const {
    content,
    isDirty,
    resetEditor,
    onBlur,
    fileKey,
    isSaving,
    initialize,
  } = useMyJoditEditor();

  const { mutate: submitMutate, isLoading: submitIsLoading } = useMutation(
    (data) => submitAssignmentPromise(data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["student-single-assignment"],
        });
        formikSubmitAssignmnet.resetForm();
        setNewFiles([]);
        resetEditor();
      },
    }
  );

  const {
    mutate: updateSubmissionMutate,
    isLoading: updateSubmissionIsLoading,
  } = useMutation(
    (data) =>
      updateAssignmentSubmissionPromise(
        resp?.data?.submission?.id,
        data,
        token
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["student-single-assignment"],
        });
        formikUpdateAssignmnetSubmission.resetForm();
        setNewFiles([]);
        resetEditor();
      },
    }
  );

  const formikSubmitAssignmnet = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      values.newFileKeys = newFiles.filter((x) => x.key).map((x) => x.key);
      values.assignmentId = id;
      values.description = fileKey;
      submitMutate(values);
    },
    enableReinitialize: true,
  });

  const formikUpdateAssignmnetSubmission = useFormik({
    initialValues: {
      deletedFileAttachmentIds: [],
    },
    onSubmit: (values) => {
      if (!resp?.data.allowSubmissionEdit) return;
      values.assignmentId = id;
      values.description = fileKey;
      values.newFileKeys = newFiles.filter((x) => x.key).map((x) => x.key);
      updateSubmissionMutate(values);
    },
    enableReinitialize: true,
  });

  const { data: resp, status } = useQuery(
    ["student-single-assignment", id],
    () => getSingleAssignmentForStudent(id, selectedGroupId, token),
    {
      onSuccess: (resp) => {
        initialize(resp.data?.submission?.description);
      },
    }
  );

  const handleAddFileBtn = () => {
    ref.current.click();
  };

  const handleInputChange = (event) => {
    const files = event.currentTarget.files;
    const updatedFiles = [
      ...newFiles,
      ...Array.from(files).map((x) => ({ file: x, id: uuidv4() })),
    ];
    setNewFiles(updatedFiles);
  };

  const onFileUpload = (id, key) => {
    setNewFiles((prev) => {
      let file = prev.find((x) => x.id == id);
      if (file == null) return prev;
      file.key = key;
      return prev;
    });
  };

  const handleRemoveFile = (id) => {
    const updatedFiles = newFiles.filter((x) => x.id != id);
    setNewFiles(updatedFiles);
  };

  const formatDate = (dateString, dateFormat) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return `${t("Invalid Date")}`;
    }
    return format(date, dateFormat);
  };

  const getDisplayDate = (item) => {
    const now = new Date();
    const deadline = new Date(item?.deadline);
    const createdTime = new Date(item?.createdTime);
    if (!item?.deadline || isNaN(deadline) || deadline < now) {
      if (isValid(createdTime)) {
        if (
          new Date(item.createdTime).getFullYear() != new Date().getFullYear()
        ) {
          return `${t("Posted")}  ${formatDate(
            item.createdTime,
            "MMM do, yyyy"
          )}`;
        }
        return `${t("Posted")}  ${formatDate(item?.createdTime, "MMM do")}`;
      } else {
        return `${t("Date is invalid")}`;
      }
    }

    if (new Date(item.deadline).getFullYear() != new Date().getFullYear()) {
      return `${t("Due")}  ${formatDate(item.deadline, "MMM do, HH:mm, yyyy")}`;
    }
    return `${t("Due")}  ${formatDate(item?.deadline, "MMM do, HH:mm")}`;
  };

  return (
    <Flex>
      <Box display={["none", "none", "block", "block"]} width="25%"></Box>
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <PageTitle
          title={`${resp?.data?.name}`}
          status={status}
          icon={faClipboardList}
        />

        <HStack w="100%" justifyContent="space-between">
          <Skeleton minW="40%" isLoaded={status == "success"}>
            <Text
              display={"flex"}
              fontSize={["12px", "14px", "14px", "18px", "22px"]}
              alignItems={"center"}
              fontWeight="600"
            >
              <ProfilePicture url={resp?.data?.teacherProfilePictureKey} />
              {`${resp?.data?.teacherName} ${resp?.data?.teacherSurname} | `}
              <Text pl="4px" as="span">
                {`${t("Posted")} - ${format(
                  resp?.data?.createdTime ?? new Date(),
                  "MMM do yyyy"
                )}`}
              </Text>
            </Text>
          </Skeleton>
        </HStack>

        <Divider mt="12px" borderColor="teal" borderWidth="2px" />

        <Skeleton mb="20px" minH="8vh" isLoaded={status == "success"}>
          <Box m="20px 0" lineHeight="22px">
            <Text
              color="#00000099"
              // w="50%"
            >
              {resp?.data?.description ? (
                <InnerHtml fileKey={resp?.data?.description} />
              ) : (
                <Text fontWeight="600" w="100%" m="3rem auto">
                  {t("No description")}
                </Text>
              )}
            </Text>
          </Box>

          <Box m="2rem 0 4rem">
            <HStack flexWrap="wrap" gap="24px">
              {resp?.data?.fileAttachments?.map((x, index) => (
                <ReadOnlyFile key={index} fileKey={x.key} />
              ))}
            </HStack>
          </Box>
        </Skeleton>

        <Box
          w={["100%", "100%", "90%", "55%"]}
          bg={colors.lightGreen}
          padding="24px 20px"
          px={["8px", "12", "16px", "20px"]}
          m="2rem 0 2rem"
        >
          <Skeleton isLoaded={status == "success"}>
            <Text
              fontSize={["12px", "14px", "16px", "20px", "24px"]}
              fontWeight="600"
            >
              {t("Assignment details")}
            </Text>
            <Flex
              margin="20px 0"
              alignItems={"center"}
              justifyContent="space-between"
            >
              <VStack alignItems="start" gap="40px">
                {resp?.data?.isGraded &&
                  resp?.data?.canSubmit &&
                  resp?.data?.submission != null && (
                    <Text fontWeight="600" fontSize={["10px", "12px", "16px"]}>
                      {t("Grade")}
                    </Text>
                  )}
                <Text fontWeight="600" fontSize={["10px", "12px", "16px"]}>
                  {t("Deadline")}
                </Text>
                <Text fontWeight="600" fontSize={["10px", "12px", "16px"]}>
                  {t("Graded")}
                </Text>
                <Text fontWeight="600" fontSize={["10px", "12px", "16px"]}>
                  {t("Submission edit possible")}
                </Text>
              </VStack>
              <VStack alignItems="end" gap="40px">
                {resp?.data?.isGraded &&
                  resp?.data?.canSubmit &&
                  resp?.data?.submission != null &&
                  (resp?.data?.submission?.grade == null ? (
                    <Text fontWeight="700" color="#fcbe03">
                      {t("Grade is pending")}{" "}
                      <FontAwesomeIcon icon={faSpinner} />
                    </Text>
                  ) : (
                    <Text fontSize={["10px", "12px", "16px"]} p="4px" as="span">
                      {resp?.data?.submission?.grade}
                      {" / 100%"}
                    </Text>
                  ))}
                <Text fontWeight="700" fontSize={["10px", "12px", "16px"]}>
                  {resp?.data?.deadline
                    ? formatDate(resp?.data?.deadline, "HH:mm, MMM do, yyy")
                    : `${t("No deadline")}`}
                </Text>
                <Text fontWeight="700" fontSize={["10px", "12px", "16px"]}>
                  {resp?.data?.isGraded ? `${t("Yes")}` : `${t("No")}`}
                </Text>
                <Text fontWeight="700" fontSize={["10px", "12px", "16px"]}>
                  {resp?.data?.allowSubmissionEdit
                    ? `${t("Yes")}`
                    : `${t("No")}`}
                </Text>
              </VStack>
            </Flex>
          </Skeleton>
        </Box>

        <Box w="80%" mb="1rem" mt="1rem">
          <Skeleton w="100%" isLoaded={status == "success"}>
            {resp?.data?.canSubmit ? (
              <HStack
                border="1px solid #EAF5F4"
                borderRadius="6px"
                textAlign="center"
                alignItems="center"
                justifyContent="center"
                gap="12px"
                width="200px"
                height="48px"
                fontWeight="500"
                fontSize="18px"
                bg="#EAF5F4"
              >
                <FontAwesomeIcon icon={faCopy} />
                <Text fontSize={["14px", "12px", "16px"]}>
                  {t("Your submission")}
                </Text>
              </HStack>
            ) : (
              <HStack
                border="1px solid #EAF5F4"
                borderRadius="6px"
                textAlign="center"
                alignItems="center"
                justifyContent="center"
                gap="12px"
                width="340px"
                color="red"
                height="48px"
                fontWeight="500"
                fontSize={["8px", "12px", "16px", "18px"]}
                bg="#FFCCCB"
              >
                <FontAwesomeIcon icon={faCopy} />
                <Text> {t("You can't submit the assignment")}</Text>
              </HStack>
            )}

            <Flex
              m="1rem 0"
              w="100%"
              gap="12px"
              alignItems="center"
              flexWrap="wrap"
            >
              {resp?.data?.submission?.fileAttachments
                .filter(
                  (x) =>
                    !formikUpdateAssignmnetSubmission.values.deletedFileAttachmentIds.some(
                      (y) => y == x.id
                    )
                )
                .map((x) =>
                  resp?.data.allowSubmissionEdit ? (
                    <ExistingFile
                      key={x.key}
                      fileKey={x.key}
                      onRemoveFunc={() =>
                        formikUpdateAssignmnetSubmission.setFieldValue(
                          "deletedFileAttachmentIds",
                          [
                            ...formikUpdateAssignmnetSubmission.values
                              .deletedFileAttachmentIds,
                            x.id,
                          ]
                        )
                      }
                    />
                  ) : (
                    <ReadOnlyFile key={x.key} fileKey={x.key} />
                  )
                )}
            </Flex>

            {resp?.data?.submission?.fileAttachments.filter(
              (x) =>
                !formikUpdateAssignmnetSubmission.values.deletedFileAttachmentIds.some(
                  (y) => y == x.id
                )
            ).length !== 0 &&
              newFiles.length !== 0 && (
                <Divider
                  m="20px 0 12px"
                  borderColor={colors.primary}
                  borderWidth="2px"
                />
              )}

            <Flex w="100%" m="1rem 0" flexWrap="wrap" gap="12px">
              {newFiles.map((x) => (
                <NewFile
                  onUploadFunc={(key) => onFileUpload(x.id, key)}
                  file={x.file}
                  key={x.id}
                  onRemoveFunc={() => handleRemoveFile(x.id)}
                />
              ))}
            </Flex>
          </Skeleton>
        </Box>

        <Skeleton isLoaded={status == "success"}>
          <Box
            w={["100%", "100%", "90%", "65%"]}
            padding="8px"
            pb={"20px"}
            border="2px solid"
            borderColor={colors.primary}
            borderRadius="12px"
            mb="1rem"
          >
            <MyJoditEditor
              isReadOnly={
                (resp?.data.submission?.submissionTime &&
                  !resp?.data.allowSubmissionEdit) ||
                !resp?.data?.canSubmit
              }
              content={content}
              isInline={true}
              isSaving={isSaving}
              onBlur={onBlur}
            />
          </Box>
        </Skeleton>

        {resp?.data.submission?.submissionTime &&
        !resp?.data.allowSubmissionEdit ? null : (
          <Skeleton m="2rem 0" isLoaded={status == "success"}>
            <ButtonGroup gap="10px" m="2rem 0">
              <Button
                color={"white"}
                bg={colors.primary}
                _hover={{ bg: "#2a6b68" }}
                w={{ base: "125px", md: "200px", sm: "140px" }} // h="40px"
                isLoading={submitIsLoading || updateSubmissionIsLoading}
                isDisabled={
                  (!formikSubmitAssignmnet.dirty &&
                    !formikUpdateAssignmnetSubmission.dirty &&
                    !isDirty &&
                    newFiles.length == 0) ||
                  isSaving
                }
                onClick={
                  resp?.data?.submission?.submissionTime
                    ? formikUpdateAssignmnetSubmission.handleSubmit
                    : formikSubmitAssignmnet.handleSubmit
                }
              >
                {resp?.data?.submission?.submissionTime ? "Save" : "Submit"}
              </Button>
              <Button
                gap="16px"
                color={colors.primary}
                bg={"white"}
                isDisabled={!resp?.data?.canSubmit}
                onClick={handleAddFileBtn}
                border="1px solid "
                w={{ md: "200px", sm: "140px" }}
                // w={["20%", "10%", "30%", "20%"]}
                // h={["36px", "40px", "48px"]}
                // h="40px"
              >
                {" "}
                <FontAwesomeIcon icon={faPlus} /> <Text>{t("Add file")}</Text>
                <input
                  onChange={handleInputChange}
                  ref={ref}
                  hidden
                  type="file"
                />
              </Button>
            </ButtonGroup>
          </Skeleton>
        )}
      </Container>
    </Flex>
  );
}

export default SingleAssignment;
