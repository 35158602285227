import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import PageTitle from "../general/components/PageTitle";
import {
  faClipboardList,
  faPaperclip,
  faPaste,
  faPlusCircle,
  faSquareFull,
  faX,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { colors } from "../Constants";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { addMaterial } from "../services/materialService";
import { useFormik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useMyJoditEditor from "../hooks/useMyJoditEditor";
import MyJoditEditor from "../helpers/MyJoditEditor";
import {
  addMaterialSchema,
  getAddMaterialSchema,
} from "./validations/addMaterialtSchema";
import NewFile from "../general/components/NewFile";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

function AddMaterialT() {
  const { t } = useTranslation("global");
  const validationSchema = getAddMaterialSchema(t);

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { token, selectedGroupId } = useSelector((x) => x.security) || {};
  const { content, isDirty, onBlur, fileKey, isSaving } = useMyJoditEditor();
  const { mutate, isLoading } = useMutation(
    (data) => addMaterial(token, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["create-material"],
        });
        navigate("/teacher/materials");
      },
    }
  );
  const [newFiles, setNewFiles] = useState([]);

  const formik = useFormik({
    initialValues: {
      name: null,
      groupId: selectedGroupId,
    },
    onSubmit: (values) => {
      values.description = fileKey;
      values.newFileKeys = newFiles.filter((x) => x.key).map((x) => x.key);
      mutate(values);
    },
    validationSchema: validationSchema,
  });
  const handleFileChange = (event) => {
    const files = event.currentTarget.files;
    const updatedFiles = [
      ...newFiles,
      ...Array.from(files).map((x) => ({ file: x, id: uuidv4() })),
    ];
    setNewFiles(updatedFiles);
  };

  const onFileUpload = (id, key) => {
    setNewFiles((prev) => {
      let file = prev.find((x) => x.id == id);
      if (file == null) return prev;
      file.key = key;
      return prev;
    });
  };

  const handleRemoveFile = (id) => {
    const updatedFiles = newFiles.filter((x) => x.id != id);
    setNewFiles(updatedFiles);
  };

  const fileInputRef = useRef(null);

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Flex>
      <Box display={["none", "none", "block", "block"]} width="25%"></Box>
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <Box>
          <PageTitle
            status={"success"}
            title={t("Add Material")}
            icon={faPaste}
          />
        </Box>
        <Flex
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          w="100%"
          margin="8px auto"
          p="20px 0"
          gap="20px"
          bg="#EAF5F4"
        >
          <FormControl w={["90%", "80%", "70%", "60%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Material name")}
            </FormLabel>
            <Input
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="name"
              bg="white"
              borderRadius="4px"
              p="0 12px"
              type="text"
              placeholder={t("Material name")}
              _placeholder={{ color: "#666666" }}
            />
            {formik.errors.name && formik.touched.name && (
              <Text p="0" pl="4px" m="0" color="red">
                {formik.errors.name}
              </Text>
            )}
          </FormControl>
          <FormControl w={["90%", "80%", "70%", "60%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Content")}
            </FormLabel>
            <MyJoditEditor
              onBlur={onBlur}
              content={content}
              isSaving={isSaving}
            />
          </FormControl>

          <FormControl
            display={"flex"}
            m="0 12px"
            w={["90%", "80%", "70%", "60%", "50%"]}
          >
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Add attachments")}
            </FormLabel>
            <FontAwesomeIcon
              color="green"
              fontSize="24px"
              icon={faPlusCircle}
              onClick={handleIconClick}
              style={{ cursor: "pointer" }}
            />
            <Input
              ref={fileInputRef}
              bg="white"
              color="#000000"
              borderRadius="4px"
              p="0 12px"
              type="file"
              multiple
              display="none"
              sx={{
                "::-webkit-file-upload-button": {
                  display: "none",
                },
              }}
              textStyle={{ placeholder: "There is no file" }}
              paddingTop="7px"
              onChange={handleFileChange}
            />
          </FormControl>
          {newFiles.length > 0 && (
            <Box w={["90%", "80%", "70%", "60%", "50%"]}>
              <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
                {t("Selected attachments:")}
              </FormLabel>

              <Flex w="100%" flexWrap="wrap" gap="12px">
                {newFiles?.map((x) => (
                  <NewFile
                    onUploadFunc={(key) => onFileUpload(x.id, key)}
                    file={x.file}
                    key={x.id}
                    onRemoveFunc={() => handleRemoveFile(x.id)}
                  />
                ))}
              </Flex>
            </Box>
          )}
          <Button
            isLoading={isLoading}
            isDisabled={(!formik.dirty && !isDirty) || isSaving}
            bg={colors.primary}
            color="white"
            w={["90%", "80%", "70%", "60%", "50%"]}
            h="40px"
            fontWeight="600"
            fontSize="16px"
            _hover={{ bg: "#2a6b68" }}
            onClick={formik.handleSubmit}
          >
            {t("Add")}
          </Button>
        </Flex>
      </Container>
    </Flex>
  );
}

export default AddMaterialT;
