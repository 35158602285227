import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { colors } from "../../Constants";
import { useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { createLesson } from "../../services/attendanceService";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

export default function AttendanceModalT() {
  const { t } = useTranslation("global");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const queryClient = useQueryClient();
  const { token, selectedGroupId } = useSelector((x) => x.security) || {};
  const { mutate } = useMutation((data) => createLesson(data, token), {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["teacher-attendance"],
      });
      onClose();
    },
  });

  const formik = useFormik({
    initialValues: {
      groupId: selectedGroupId,
      conductedDate: null,
    },
    onSubmit: (values) => {
      mutate(values);
    },
  });
  return (
    <>
      <Button
        colorScheme="teal"
        border="1px solid "
        onClick={onOpen}
        w={["40%", "30%", "20%", "15%"]}
      >
        <FontAwesomeIcon icon={faPlus} /> <Text>{t("Add")}</Text>
      </Button>

      <Modal size="lg" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          p="28px 8px "
          containerProps={{ top: "100px" }}
          w="684px"
          h="286px"
        >
          <ModalHeader>
            <Text
              as="h1"
              fontSize="20px"
              color={colors.primary}
              fontWeight="bold"
            >
              {t("Select Date")}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={2}>
            <FormControl>
              <Input
                type="date"
                value={formik.values.conductedDate}
                onChange={(e) => {
                  formik.setFieldValue("conductedDate", e.target.value);
                }}
              />
              <FormLabel fontSize="16px" color="gray" fontWeight="400" m="8px">
                {t("Month, Day, Year")}
              </FormLabel>
            </FormControl>
          </ModalBody>

          <ModalFooter pt="0px" justifyContent="start">
            {" "}
            <Button
              onClick={() => {
                formik.handleSubmit();

                onClose();
              }}
              w="50%"
              colorScheme="teal"
              mr={3}
            >
              {t("Save")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
