import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import PageTitle from "../general/components/PageTitle";
import {
  faClipboardList,
  faTableList,
} from "@fortawesome/free-solid-svg-icons";
import SearchBar from "./components/SearchBar";
import { colors } from "../Constants";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { getExamSearch, getExams } from "../services/examService";
import { format, isValid } from "date-fns";
import IconAndCard from "../general/cards/IconAndCard";
import { useTranslation } from "react-i18next";

function Exams() {
  const navigate = useNavigate();
  const { t } = useTranslation("global");
  const { token, selectedGroupId } = useSelector((x) => x.security);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(8);
  const scrollPositionRef = useRef(0);

  const {
    data: resp,
    status,
    refetch: refetchExams,
  } = useQuery(
    ["student-exams", selectedGroupId, token, skip, take],
    () => getExams(selectedGroupId, token, skip, take || resp?.data?.length),
    { keepPreviousData: take !== null }
  );

  const formatDate = (dateString, dateFormat) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return `${t("Invalid Date")}`;
    }
    return format(date, dateFormat);
  };

  const getDisplayDate = (item) => {
    const now = new Date();
    const deadline = new Date(item.deadline);
    const createdTime = new Date(item.createdTime);
    if (!item.deadline || isNaN(deadline) || deadline < now) {
      if (isValid(createdTime)) {
        if (
          new Date(item.createdTime).getFullYear() != new Date().getFullYear()
        ) {
          return `${t("Posted")} ${formatDate(item.createdTime, "MMM do, yyyy")}`;
        }
        return `${t("Posted")}  ${formatDate(item.createdTime, "MMM do")}`;
      } else {
        return `${t("Posted date is invalid")}`;
      }
    }

    if (new Date(item.deadline).getFullYear() != new Date().getFullYear()) {
      return `${t("Due")} ${formatDate(item.deadline, "MMM do, HH:mm, yyyy")}`;
    }
    return `${t("Due")} ${formatDate(item.deadline, "MMM do, HH:mm")}`;
  };

  const handleSearchChange = async (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    if (term.length > 0) {
      try {
        const response = await getExamSearch(selectedGroupId, token, term);
        setSearchResults(response.data);
        setShowDropdown(true);
      } catch (error) {
        console.error("Error", error);
      }
    } else {
      setShowDropdown(false);
      setSearchResults([]);
    }
  };

  const handleLoadMore = () => {
    scrollPositionRef.current = window.scrollY;
    const newTake = take + 5;
    setTake(newTake);
    refetchExams();

    const params = new URLSearchParams(window.location.search);
    params.set("take", newTake);
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${params}`
    );
  };

  const handleLoadAll = () => {
    scrollPositionRef.current = window.scrollY;
    const newTake = null;
    setTake(newTake);
    refetchExams();

    const params = new URLSearchParams(window.location.search);
    params.set("take", "all");
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${params}`
    );
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const urlTake = params.get("take");

    if (urlTake) {
      setTake(Number(urlTake));
    }

    refetchExams();
  }, [selectedGroupId, token, refetchExams]);

  useEffect(() => {
    window.scrollTo(0, scrollPositionRef.current);
  }, [resp]);

  const renderExams = (exams) =>
    exams?.map((exam) => (
      <Flex
        padding="16px 20px"
        border="2px solid"
        borderColor={colors.primary}
        borderRadius="12px"
        color={colors.primary}
        alignItems="center"
        justifyContent="space-between"
        sx={{
          "@media (max-width: 610px)": {
            padding: "8px 8px",
          },
          "@media (max-width: 420px)": {
            padding: "4px",
          },
        }}
      >
        <Link
          to={`/student/exams/${exam?.id}`}
          onClick={() => {
            scrollPositionRef.current = window.scrollY;
          }}
        >
          <HStack
            fontSize="32px"
            sx={{
              "@media (max-width: 610px)": {
                fontSize: "30px",
              },
              "@media (max-width: 420px)": {
                fontSize: "20px",
              },
            }}
            transition=".2s"
            _hover={{ color: "#3a706e" }}
          >
            <FontAwesomeIcon fontSize="32px" icon={faClipboardList} />
            <Text
              sx={{
                "@media (max-width: 610px)": {
                  fontSize: "18px",
                },
                "@media (max-width: 500px)": {
                  fontSize: "16px",
                },
                "@media (max-width: 420px)": {
                  fontSize: "12px",
                },
              }}
              fontSize="18px"
              fontWeight="600"
              w="100%"
            >
              {exam?.name}
            </Text>
          </HStack>
        </Link>
        <Text
          sx={{
            "@media (max-width: 500px)": {
              fontSize: "12px",
            },
            "@media (max-width: 420px)": {
              fontSize: "10px",
            },
          }}
          color="black"
          textAlign="end"
          w="44%"
        >
          {getDisplayDate(exam)}
        </Text>
      </Flex>
    ));

  return (
    <Flex>
      <Box display={["none", "none", "block", "block"]} width="25%"></Box>
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <PageTitle title={t("Exams")} status={"success"} icon={faTableList} />
        <Box
          w="24%"
          sx={{
            "@media (min-width: 1024px)": {
              w: "240px",
            },
            "@media (max-width: 1024px)": {
              w: "210px",
            },

            "@media (max-width: 768px)": {
              w: "250px",
            },
            "@media (max-width: 425px)": {
              w: "220px",
            },
            "@media (max-width: 375px)": {
              w: "202px",
            },
          }}
        >
          <SearchBar
            onChange={handleSearchChange}
            disabled={
              status === "loading" ||
              status === "error" ||
              (status === "success" && (!resp || resp?.data?.length === 0))
            }
          />
          {showDropdown && (
            <Box
              sx={{
                "::-webkit-scrollbar-thumb": {
                  borderRadius: "12px",
                  background: colors.primary,
                },
                "::-webkit-scrollbar": {
                  width: "10px",
                },

                "@media (max-width: 910px)": {
                  w: "40%",
                },
                "@media (max-width: 750px)": {
                  w: "40%",
                },
                "@media (max-width: 610px)": {
                  w: "40%",
                },
                "@media (max-width: 420px)": {
                  w: "60%",
                },
              }}
              w="30%"
              position="absolute"
              zIndex="1000"
              maxHeight="400px"
              overflowY="auto"
              border="1px solid #ddd"
              borderRadius="4px"
              bg="white"
              boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
              mt="2"
            >
              {searchResults.length === 0 ? (
                <Text p="4" textAlign="center">
                  {t("No results found for")} "
                  <Text as="span" color="red">
                    {searchTerm}
                  </Text>
                  "
                </Text>
              ) : (
                searchResults.map((result) => (
                  <Box
                    key={result.id}
                    p="4"
                    borderBottom="1px solid #eee"
                    cursor="pointer"
                    onClick={() => {
                      setShowDropdown(false);
                      navigate(`/student/exams/${result.id}`);
                    }}
                  >
                    {result.name}
                  </Box>
                ))
              )}
            </Box>
          )}
        </Box>
        <Box m="20px 0 28px">
          <Flex flexDir="column" gap="24px">
            {status == "loading" && (
              <>
                {Array.from({ length: 5 }, () => (
                  <Skeleton>
                    <IconAndCard />
                  </Skeleton>
                ))}
              </>
            )}

            {resp && status === "success" && renderExams(resp?.data)}
          </Flex>

          {resp?.data?.length > 0 &&
            take !== null &&
            resp?.data?.length >= take && (
              <Flex
                minH={resp?.data?.length < 5 ? "332px" : undefined}
                maxH={resp?.data?.length >= 5 ? "332px" : undefined}
                alignItems="end"
                justifyContent="center"
                gap="8px"
              >
                <Flex
                  mt="24px"
                  justifyContent="center"
                  alignItems="center"
                  gap="24px"
                  sx={{
                    "@media (max-width: 520px)": {
                      w: "60%",
                    },
                    "@media (max-width: 420px)": {
                      w: "80%",
                    },
                  }}
                >
                  <Button
                    onClick={handleLoadMore}
                    bg="white"
                    color="#319795"
                    w="70%"
                    h="40px"
                    border={`1px solid ${colors.primary}`}
                    sx={{
                      "@media (max-width: 520px)": {
                        fontSize: "12px",
                      },
                      "@media (max-width: 420px)": {
                        fontSize: "12px",
                        w: "100%",
                        p: "0 28px",
                      },
                    }}
                  >
                    {t("Load More")}
                  </Button>
                  <Button
                    onClick={handleLoadAll}
                    bg="white"
                    color="#319795"
                    w="70%"
                    h="40px"
                    sx={{
                      "@media (max-width: 520px)": {
                        fontSize: "12px",
                      },
                      "@media (max-width: 420px)": {
                        fontSize: "12px",
                        w: "100%",
                        p: "0 28px",
                      },
                    }}
                    border={`1px solid ${colors.primary}`}
                  >
                    {t("Load All")}
                  </Button>
                </Flex>
              </Flex>
            )}
        </Box>

        {status === "error" && (
          <Box m="3rem 0" textAlign="center">
            <Text fontWeight="600" fontSize="24px" color="red">
              {("errMessages.message1")}
            </Text>
            <Text fontWeight="600" fontSize="24px" color="red">
            {("errMessages.message2")}

            </Text>
          </Box>
        )}

        {status === "success" && resp?.data?.length === 0 && (
          <Box m="3rem 0" textAlign="center">
            <Text fontWeight="600" color="red" fontSize="24px">
              {t("No exams found.")}
            </Text>
          </Box>
        )}
      </Container>
    </Flex>
  );
}

export default Exams;
