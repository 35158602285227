import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  HStack,
  List,
  ListItem,
  Skeleton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  faClipboardList,
  faCopy,
  faEdit,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import PageTitle from "../general/components/PageTitle";
import { colors } from "../Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
  deleteGroup,
  getGroupDetailsPromise,
  removeStudentFromGroup,
  removeTeacherFromGroup,
} from "../services/groupService";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { getStudentsForGroupPromise } from "../services/studentService";
import { getTeachersForGroupPromise } from "../services/teacherService";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "../teacher/modal/ConfirmationModalT";
import RemoveModal from "./modal/ConfirmationModalM";
import RemoveTeacherModal from "./modal/RemoveTeacherModal";
import ProfilePicture from "../general/components/ProfilePicture";
import SimpleBar from "simplebar-react";

export default function SingleGroup() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { token } = useSelector((x) => x.security) || {};
  const [tabIndex, setTabIndex] = useState(0);
  const { data: respGroupDetails, status: statusGroupDetails } = useQuery(
    ["group-details", token, id],
    () => getGroupDetailsPromise(id, token)
  );
  const {
    data: respStudents,
    status: statusStudents,
    refetch: refetchStudents,
  } = useQuery(
    ["group-students", token, id, tabIndex],
    () => getStudentsForGroupPromise(id, token),
    { enabled: tabIndex == 0 }
  );

  const {
    data: respTeachers,
    status: statusTeachers,
    refetch: refetchTeachers,
  } = useQuery(
    ["group-teachers", token, id, tabIndex],
    () => getTeachersForGroupPromise(id, token),
    { enabled: tabIndex == 1 }
  );

  //!
  //remove student and teacher

  const removeStudent = useMutation(
    ({ groupStudentId, saveHistory }) =>
      removeStudentFromGroup(groupStudentId, token, saveHistory),
    {
      onSuccess: () => {
        refetchStudents();
      },
    }
  );

  const handleRemoveStudent = (groupStudentId, saveHistory) => {
    removeStudent.mutate({ groupStudentId, saveHistory });
  };

  const { t } = useTranslation("global");

  const { mutate: removeTeacher, isLoading: isRemovingTeacher } = useMutation(
    (teacherId) => removeTeacherFromGroup(teacherId, token),
    {
      onSuccess: () => {
        refetchTeachers();
      },
    }
  );

  const handleRemoveTeacher = (groupTeacherId) => {
    removeTeacher(groupTeacherId);
  };

  ///delete group

  const { mutate: deleteGroupMutate, isLoading: isDeletingGroup } = useMutation(
    () => deleteGroup(id, token),
    {
      onSuccess: () => {
        navigate("/manager/groups");
      },
    }
  );

  const handleDeleteGroupConfirm = () => {
    deleteGroupMutate();
  };

  const [isLessThan610px] = useMediaQuery("(max-width: 1300px)");

  return (
    <Flex>
      <Box display={["none", "none", "block", "block"]} width="25%"></Box>
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <PageTitle
          status={"success"}
          title={t("sidebar.groups.group")}
          icon={faClipboardList}
        />
        <HStack w="100%" justifyContent="space-between" alignItems="center">
          <Skeleton minW="40%" isLoaded={statusGroupDetails == "success"}>
            <Text
              fontSize={["12px", "14px", "14px", "18px", "22px"]}
              fontWeight="500"
            >
              {`${respGroupDetails?.data?.name} | ${t("Created on")} ${format(
                respGroupDetails?.data?.createdTime ?? new Date(),
                "dd/MM/yyyy"
              )}`}
            </Text>
          </Skeleton>

          <Box textAlign="start">
            <Flex gap="20px">
              <Button
                onClick={() => navigate(`/manager/groups/edit-group/${id}`)}
                gap="16px"
                _hover={{ bg: "#2a6b68" }}
                color={"white"}
                bg={colors.primary}
                w={["60px", "70px", "80px", "110px", "160px"]}
                h={["24px", "28px", "30px", "40px"]}
                fontSize={["10px", "10px", "12px", "14px", "16px"]}
              >
                <FontAwesomeIcon icon={faEdit} />{" "}
                <Text>{t("actions.edit")}</Text>
              </Button>

              <ConfirmationModal
                fontSize={["10px", "10px", "12px", "14px", "16px"]}
                buttonWidth={["60px", "70px", "80px", "110px", "160px"]}
                buttonH={["24px", "28px", "30px", "40px"]}
                onConfirm={handleDeleteGroupConfirm}
                isLoading={isDeletingGroup}
              />
            </Flex>
          </Box>
        </HStack>

        <Divider w="100%" mt="20px" borderColor="teal" borderWidth="2px" />

        <Tabs variant={"unstyled"} onChange={(i) => setTabIndex(i)}>
          <HStack gap="20px">
            <TabList>
              <Tab>
                <HStack
                  transition=".3s"
                  mt="3rem"
                  borderRadius="6px"
                  textAlign="center"
                  alignItems="center"
                  justifyContent="center"
                  gap="12px"
                  // width="180px"
                  padding="20px"
                  height="48px"
                  fontWeight="500"
                  fontSize={["12px", "14px", "16px", "18px"]}
                  bg={!tabIndex && "#EAF5F4"}
                  _hover={tabIndex && { bg: "#EAF5F4", cursor: "pointer" }}
                  cursor="default"
                >
                  <FontAwesomeIcon icon={faCopy} />
                  <Text>{t("sidebar.students")}</Text>
                </HStack>
              </Tab>
              <Tab>
                <HStack
                  padding="20px"
                  transition=".3s"
                  _hover={{ bg: "#EAF5F4", cursor: "pointer" }}
                  mt="3rem"
                  borderRadius="6px"
                  textAlign="center"
                  alignItems="center"
                  justifyContent="center"
                  gap="12px"
                  bg={tabIndex && "#EAF5F4"}
                  // width="180px"
                  height="48px"
                  fontWeight="500"
                  fontSize={["12px", "14px", "16px", "18px"]}
                >
                  <FontAwesomeIcon icon={faCopy} />
                  <Text cursor="pointer">{t("sidebar.teachers.teachers")}</Text>
                </HStack>
              </Tab>
            </TabList>
          </HStack>

          <TabPanels>
            <TabPanel>
              {statusStudents === "success" &&
                respStudents?.data?.length !== 0 && (
                  <Box w="100%" mb="28px">
                    {isLessThan610px ? (
                      <SimpleBar
                        autoHide={false}
                        style={{ maxHeight: "calc(100vh - 100px)" }}
                      >
                        <Box
                          sx={{
                            "@media (max-width: 1300px)": {
                              minWidth: "1300px",
                            },
                          }}
                        >
                          {(statusStudents == "loading" ||
                            statusTeachers == "loading" ||
                            respStudents?.data?.length > 0 ||
                            respTeachers?.data?.length > 0) && (
                            <HStack
                              m="2rem 0"
                              mt={["1rem", "2rem"]}
                              padding="14px"
                              bg="#EAF5F4"
                              fontSize={[
                                "12px",
                                "12px",
                                "12px",
                                "12px",
                                "16px",
                              ]}
                              justifyContent="space-between"
                              h={["20px", "40px", "60px"]}
                              color={colors.primary}
                              w="100%"
                            >
                              <Text textAlign="start" w="18%" as="i">
                                {t("table.name")}
                              </Text>
                              <Text textAlign="center" w="12%" as="i">
                                {t("table.username")}
                              </Text>
                              <Text textAlign="center" w="24%" as="i">
                                {t("table.email")}
                              </Text>
                              <Text textAlign="center" w="18%" as="i">
                                {t("table.phone")}
                              </Text>
                              <Text textAlign="center" w="18%" as="i">
                                Date joined
                              </Text>
                              <Text textAlign="center" w="6%" as="i"></Text>
                            </HStack>
                          )}
                          <List mb="2rem" w="100%">
                            {statusStudents == "loading" &&
                              Array.from({ length: 5 }).map(() => (
                                <Skeleton>
                                  <ListItem
                                    fontSize="16px"
                                    fontWeight="500"
                                    w="100%"
                                    padding="20px 14px"
                                  ></ListItem>
                                  <Divider
                                    w="100%"
                                    mb="16px"
                                    borderColor={"gray.300"}
                                    borderWidth="1px solid red"
                                  />
                                </Skeleton>
                              ))}
                            {statusStudents == "success" &&
                              respStudents?.data?.map((x) => (
                                <>
                                  <ListItem
                                    cursor="default"
                                    fontWeight="500"
                                    w="100%"
                                    padding="4px"
                                    fontSize={["12px", "12px", "12px", "16px"]}
                                    px={[
                                      "12px",
                                      "12px",
                                      "8px",
                                      "10px",
                                      "12px",
                                      "16px",
                                    ]}
                                  >
                                    <Flex
                                      alignItems="center"
                                      justifyContent="space-between"
                                    >
                                      <Text
                                        display={"flex"}
                                        alignItems={"center"}
                                        transition=".3s"
                                        _hover={{
                                          color: "teal.400",
                                          cursor: "pointer",
                                        }}
                                        w="18%"
                                      >
                                        <ProfilePicture
                                          url={x.profilePictureKey}
                                        />
                                        {`${x.name} ${x.surname}`}
                                      </Text>
                                      <Text textAlign="center" w="12%">
                                        {x.username}
                                      </Text>
                                      <Text textAlign="center" w="24%">
                                        {x.email}
                                      </Text>
                                      <Text textAlign="center" w="18%">
                                        {x.phone}
                                      </Text>
                                      <Text textAlign="center" w="18%">
                                        {format(x.createdTime, "dd MMM yyy")}
                                      </Text>
                                      <Text textAlign="center" w="6%">
                                        <RemoveModal
                                          iconFontSize={"20px"}
                                          buttonWidth={"0px"}
                                          buttonH={"0"}
                                          iconColor="#e61919"
                                          text=""
                                          onConfirm={handleRemoveStudent}
                                          isLoading={removeStudent.isLoading}
                                          icon={faXmark}
                                          groupStudentId={x.groupStudentId}
                                        />
                                      </Text>
                                    </Flex>
                                  </ListItem>
                                  <Divider
                                    w="100%"
                                    mb="16px"
                                    borderColor={"gray.300"}
                                    borderWidth="1px solid red"
                                  />
                                </>
                              ))}
                          </List>
                        </Box>
                      </SimpleBar>
                    ) : (
                      <Box>
                        {(statusStudents == "loading" ||
                          statusTeachers == "loading" ||
                          respStudents?.data?.length > 0 ||
                          respTeachers?.data?.length > 0) && (
                          <HStack
                            m="2rem 0"
                            mt={["1rem", "2rem"]}
                            padding="14px"
                            bg="#EAF5F4"
                            fontSize={["12px", "12px", "12px", "12px", "16px"]}
                            justifyContent="space-between"
                            h={["20px", "40px", "60px"]}
                            color={colors.primary}
                            w="100%"
                          >
                            <Text textAlign="start" w="18%" as="i">
                              {t("table.name")}
                            </Text>
                            <Text textAlign="center" w="12%" as="i">
                              {t("table.username")}
                            </Text>
                            <Text textAlign="center" w="24%" as="i">
                              {t("table.email")}
                            </Text>
                            <Text textAlign="center" w="18%" as="i">
                              {t("table.phone")}
                            </Text>
                            <Text textAlign="center" w="18%" as="i">
                              Date joined
                            </Text>
                            <Text textAlign="center" w="6%" as="i"></Text>
                          </HStack>
                        )}
                        <List mb="2rem" w="100%">
                          {statusStudents == "loading" &&
                            Array.from({ length: 5 }).map(() => (
                              <Skeleton>
                                <ListItem
                                  fontSize="16px"
                                  fontWeight="500"
                                  w="100%"
                                  padding="20px 14px"
                                ></ListItem>
                                <Divider
                                  w="100%"
                                  mb="16px"
                                  borderColor={"gray.300"}
                                  borderWidth="1px solid red"
                                />
                              </Skeleton>
                            ))}
                          {statusStudents == "success" &&
                            respStudents?.data?.map((x) => (
                              <>
                                <ListItem
                                  cursor="default"
                                  fontWeight="500"
                                  w="100%"
                                  padding="4px"
                                  fontSize={["12px", "12px", "12px", "16px"]}
                                  px={[
                                    "12px",
                                    "12px",
                                    "8px",
                                    "10px",
                                    "12px",
                                    "16px",
                                  ]}
                                >
                                  <Flex
                                    alignItems="center"
                                    justifyContent="space-between"
                                  >
                                    <Text
                                      display={"flex"}
                                      alignItems={"center"}
                                      transition=".3s"
                                      _hover={{
                                        color: "teal.400",
                                        cursor: "pointer",
                                      }}
                                      w="18%"
                                    >
                                      <ProfilePicture
                                        url={x.profilePictureKey}
                                      />
                                      {`${x.name} ${x.surname}`}
                                    </Text>
                                    <Text textAlign="center" w="12%">
                                      {x.username}
                                    </Text>
                                    <Text textAlign="center" w="24%">
                                      {x.email}
                                    </Text>
                                    <Text textAlign="center" w="18%">
                                      {x.phone}
                                    </Text>
                                    <Text textAlign="center" w="18%">
                                      {format(x.createdTime, "dd MMM yyy")}
                                    </Text>
                                    <Text textAlign="center" w="6%">
                                      <RemoveModal
                                        iconFontSize={"20px"}
                                        buttonWidth={"0px"}
                                        buttonH={"0"}
                                        iconColor="#e61919"
                                        text=""
                                        onConfirm={handleRemoveStudent}
                                        isLoading={removeStudent.isLoading}
                                        icon={faXmark}
                                        groupStudentId={x.groupStudentId}
                                      />
                                    </Text>
                                  </Flex>
                                </ListItem>
                                <Divider
                                  w="100%"
                                  mb="16px"
                                  borderColor={"gray.300"}
                                  borderWidth="1px solid red"
                                />
                              </>
                            ))}
                        </List>
                      </Box>
                    )}
                  </Box>
                )}
            </TabPanel>
            <TabPanel>
              {statusTeachers === "success" &&
                respTeachers?.data?.length !== 0 && (
                  <Box mb="28px" w="100%">
                    {isLessThan610px ? (
                      <SimpleBar
                        autoHide={false}
                        style={{ maxHeight: "calc(100vh - 100px)" }}
                      >
                        <Box
                          sx={{
                            "@media (max-width: 1300px)": {
                              minWidth: "1300px",
                            },
                          }}
                        >
                          {(statusStudents == "loading" ||
                            statusTeachers == "loading" ||
                            respStudents?.data?.length > 0 ||
                            respTeachers?.data?.length > 0) && (
                            <HStack
                              m="2rem 0"
                              mt={["1rem", "2rem"]}
                              padding="14px"
                              bg="#EAF5F4"
                              fontSize={[
                                "12px",
                                "12px",
                                "12px",
                                "12px",
                                "16px",
                              ]}
                              justifyContent="space-between"
                              h={["20px", "40px", "60px"]}
                              color={colors.primary}
                              w="100%"
                            >
                              <Text textAlign="start" w="18%" as="i">
                                {t("table.name")}
                              </Text>
                              <Text textAlign="center" w="12%" as="i">
                                {t("table.username")}
                              </Text>
                              <Text textAlign="center" w="24%" as="i">
                                {t("table.email")}
                              </Text>
                              <Text textAlign="center" w="18%" as="i">
                                {t("table.phone")}
                              </Text>
                              <Text textAlign="center" w="18%" as="i">
                                {t("Date joined")}
                              </Text>
                              <Text textAlign="center" w="6%" as="i"></Text>
                            </HStack>
                          )}
                          <List mb="3rem" w="100%">
                            {respTeachers == "loading" &&
                              Array.from({ length: 5 }).map(() => (
                                <Skeleton>
                                  <ListItem
                                    fontSize="16px"
                                    fontWeight="500"
                                    w="100%"
                                    padding="20px 14px"
                                  ></ListItem>
                                  <Divider
                                    w="100%"
                                    mb="16px"
                                    borderColor={"gray.300"}
                                    borderWidth="1px solid red"
                                  />
                                </Skeleton>
                              ))}
                            {statusTeachers == "success" &&
                              respTeachers?.data?.map((x) => (
                                <>
                                  <ListItem
                                    cursor="default"
                                    fontWeight="500"
                                    w="100%"
                                    padding="4px"
                                    fontSize={["12px", "12px", "12px", "16px"]}
                                    px={[
                                      "12px",
                                      "12px",
                                      "8px",
                                      "10px",
                                      "12px",
                                      "16px",
                                    ]}
                                  >
                                    <Flex
                                      alignItems="center"
                                      justifyContent="space-between"
                                    >
                                      <Text
                                        display={"flex"}
                                        alignItems={"center"}
                                        transition=".3s"
                                        _hover={{
                                          color: "teal.400",
                                          cursor: "pointer",
                                        }}
                                        w="18%"
                                      >
                                        <ProfilePicture
                                          url={x.profilePictureKey}
                                        />
                                        {`${x.name} ${x.surname}`}
                                      </Text>
                                      <Text textAlign="center" w="12%">
                                        {x.username}
                                      </Text>
                                      <Text textAlign="center" w="24%">
                                        {x.email}
                                      </Text>
                                      <Text textAlign="center" w="18%">
                                        {x.phone}
                                      </Text>
                                      <Text textAlign="center" w="18%">
                                        {format(x.createdTime, "dd MMM yyy")}
                                      </Text>
                                      <Text textAlign="center" w="6%">
                                        <RemoveModal
                                          iconFontSize={"20px"}
                                          buttonWidth={"0px"}
                                          buttonH={"0"}
                                          iconColor="#e61919"
                                          text=""
                                          onConfirm={handleRemoveTeacher}
                                          isLoading={removeTeacher.isLoading}
                                          icon={faXmark}
                                          groupTeacherId={x.groupTeacherId}
                                        />
                                      </Text>
                                    </Flex>
                                  </ListItem>
                                  <Divider
                                    w="100%"
                                    mb="16px"
                                    borderColor={"gray.300"}
                                    borderWidth="1px solid red"
                                  />
                                </>
                              ))}
                          </List>
                        </Box>
                      </SimpleBar>
                    ) : (
                      <Box>
                        {(statusStudents == "loading" ||
                          statusTeachers == "loading" ||
                          respStudents?.data?.length > 0 ||
                          respTeachers?.data?.length > 0) && (
                          <HStack
                            m="2rem 0"
                            mt={["1rem", "2rem"]}
                            padding="14px"
                            bg="#EAF5F4"
                            fontSize={["12px", "12px", "12px", "12px", "16px"]}
                            justifyContent="space-between"
                            h={["20px", "40px", "60px"]}
                            color={colors.primary}
                            w="100%"
                          >
                            <Text textAlign="start" w="18%" as="i">
                              {t("table.name")}
                            </Text>
                            <Text textAlign="center" w="12%" as="i">
                              {t("table.username")}
                            </Text>
                            <Text textAlign="center" w="24%" as="i">
                              {t("table.email")}
                            </Text>
                            <Text textAlign="center" w="18%" as="i">
                              {t("table.phone")}
                            </Text>
                            <Text textAlign="center" w="18%" as="i">
                              {t("Date joined")}
                            </Text>
                            <Text textAlign="center" w="6%" as="i"></Text>
                          </HStack>
                        )}
                        <List mb="3rem" w="100%">
                          {respTeachers == "loading" &&
                            Array.from({ length: 5 }).map(() => (
                              <Skeleton>
                                <ListItem
                                  fontSize="16px"
                                  fontWeight="500"
                                  w="100%"
                                  padding="20px 14px"
                                ></ListItem>
                                <Divider
                                  w="100%"
                                  mb="16px"
                                  borderColor={"gray.300"}
                                  borderWidth="1px solid red"
                                />
                              </Skeleton>
                            ))}
                          {statusTeachers == "success" &&
                            respTeachers?.data?.map((x) => (
                              <>
                                <ListItem
                                  cursor="default"
                                  fontWeight="500"
                                  w="100%"
                                  padding="4px"
                                  fontSize={["12px", "12px", "12px", "16px"]}
                                  px={[
                                    "12px",
                                    "12px",
                                    "8px",
                                    "10px",
                                    "12px",
                                    "16px",
                                  ]}
                                >
                                  <Flex
                                    alignItems="center"
                                    justifyContent="space-between"
                                  >
                                    <Text
                                      display={"flex"}
                                      alignItems={"center"}
                                      transition=".3s"
                                      _hover={{
                                        color: "teal.400",
                                        cursor: "pointer",
                                      }}
                                      w="18%"
                                    >
                                      <ProfilePicture
                                        url={x.profilePictureKey}
                                      />
                                      {`${x.name} ${x.surname}`}
                                    </Text>
                                    <Text textAlign="center" w="12%">
                                      {x.username}
                                    </Text>
                                    <Text textAlign="center" w="24%">
                                      {x.email}
                                    </Text>
                                    <Text textAlign="center" w="18%">
                                      {x.phone}
                                    </Text>
                                    <Text textAlign="center" w="18%">
                                      {format(x.createdTime, "dd MMM yyy")}
                                    </Text>
                                    <Text textAlign="center" w="6%">
                                      <RemoveModal
                                        iconFontSize={"20px"}
                                        buttonWidth={"0px"}
                                        buttonH={"0"}
                                        iconColor="#e61919"
                                        text=""
                                        onConfirm={handleRemoveTeacher}
                                        isLoading={removeTeacher.isLoading}
                                        icon={faXmark}
                                        groupTeacherId={x.groupTeacherId}
                                      />
                                    </Text>
                                  </Flex>
                                </ListItem>
                                <Divider
                                  w="100%"
                                  mb="16px"
                                  borderColor={"gray.300"}
                                  borderWidth="1px solid red"
                                />
                              </>
                            ))}
                        </List>
                      </Box>
                    )}
                  </Box>
                )}
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Box m="3rem 0" textAlign="center">
          {(statusStudents === "error" || statusTeachers === "error") && (
            <>
              <Text fontWeight="600" fontSize="24px" color="red">
                {"errMessages.message1"}
              </Text>
              <Text fontWeight="600" fontSize="24px" color="red">
                {"errMessages.message2"}
              </Text>
            </>
          )}

          {statusStudents === "success" && respStudents?.data?.length === 0 && (
            <Text fontWeight="600" fontSize="24px" color="red">
              {t("No students found in this group yet.")}
            </Text>
          )}

          {statusTeachers === "success" && respTeachers?.data?.length === 0 && (
            <Text fontWeight="600" fontSize="24px" color="red">
              {t("No teachers assigned to this group yet.")}
            </Text>
          )}
        </Box>
      </Container>
    </Flex>
  );
}
