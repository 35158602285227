import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  HStack,
  List,
  ListItem,
  Skeleton,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useState } from "react";
import PageTitle from "../general/components/PageTitle";
import {
  faClipboardList,
  faCopy,
  faCreditCard,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { colors } from "../Constants";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getGroupsOfStudentForStudentPromise } from "../services/studentService";
import { useQuery } from "react-query";
import { getStudentPaymentsPromise } from "../services/studentPaymentService";
import { format } from "date-fns";
import PaymentReminderCard from "../manager/cards/PaymentReminderCard";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SimpleBar from "simplebar-react";

export default function StudentPaymentDetailsS({ hasLayout = true }) {
  const { token } = useSelector((x) => x.security);
  const [groupStudentId, setGroupStudentId] = useState(null);
  const { data: groupsResp, status: groupsSatus } = useQuery(
    ["groups-of-student", token],
    () => getGroupsOfStudentForStudentPromise(token),
    {
      onSuccess: (resp) => {
        !groupStudentId &&
          setGroupStudentId(resp?.data?.groups[0]?.groupStudentId);
      },
    }
  );
  const { data: studentPaymentsResp, status: studentPaymentsStatus } = useQuery(
    ["student-payments", token, groupStudentId],
    () => getStudentPaymentsPromise(groupStudentId, token),
    { enabled: groupStudentId != null }
  );
  const { t } = useTranslation("global");

  const [isLessThan610px] = useMediaQuery("(max-width: 680px)");

  return (
    <Flex>
      {hasLayout ? (
        <Box width="25%" display={["none", "none", "block", "block"]}></Box>
      ) : null}
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <PageTitle
          status={"success"}
          title={t("My payments")}
          icon={faCreditCard}
          backPath={hasLayout ? "/student/general" : "/student/select-group"}
        />
        <Divider
          w="100%"
          mx="0"
          my={["1rem", "1rem", "2rem"]}
          borderColor="teal"
          borderWidth="2px"
        />
        <Box top={0} textAlign="start">
          {groupsSatus === "error" && (
            <>
              <Text fontWeight="600" fontSize="20px" color="red">
                {t("errMessages.message1")}
              </Text>
            </>
          )}

          {groupsSatus === "success" && groupsResp?.data?.length === 0 && (
            <Text>{t("errMessages.message2")}</Text>
          )}
        </Box>
        <HStack wrap="wrap" gap="20px">
          {groupsSatus == "success" &&
            groupsResp?.data?.groups.map((x) => (
              <HStack
                transition=".3s"
                borderRadius="6px"
                onClick={() => setGroupStudentId(x.groupStudentId)}
                w={["16%"]}
                height={["32px", "48px", "40px", "48px"]}
                border={x.groupStudentId == groupStudentId && "2px solid teal"}
                fontWeight="500"
                fontSize={["10px", "14px", "16px", "18px"]}
                textAlign="center"
                alignItems="center"
                justifyContent="center"
                cursor="default"
                bg={"#EAF5F4"}
              >
                <Text>{x.groupName}</Text>
              </HStack>
            ))}
          {groupsSatus == "loading" &&
            Array.from({ length: 3 }).map(() => (
              <Skeleton>
                <HStack
                  transition=".3s"
                  borderRadius="6px"
                  w={["16%"]}
                  height={["20px", "48px", "40px", "48px"]}
                  fontWeight="500"
                  fontSize={["10px", "14px", "16px", "18px"]}
                  textAlign="center"
                  alignItems="center"
                  justifyContent="center"
                  bg={"#EAF5F4"}
                ></HStack>
              </Skeleton>
            ))}
        </HStack>

        {groupsResp?.data?.groups?.length > 0 && (
          <PaymentReminderCard
            isReadOnly={true}
            groupStudentId={groupStudentId}
          />
        )}

        {studentPaymentsResp?.data?.length !== 0 &&
          groupsResp?.data?.groups?.length !== 0 && (
            <Box w="100%">
              {isLessThan610px ? (
                <SimpleBar
                  autoHide={false}
                  style={{ maxHeight: "calc(100vh - 100px)" }}
                >
                  <Box
                    sx={{
                      "@media (max-width: 610px)": {
                        minW: "710px",
                      },
                    }}
                  >
                    <HStack
                      m="3.5rem 0 1rem"
                      mt={["1rem", "2rem", "3rem", "3.5rem"]}
                      padding="14px"
                      bg="#EAF5F4"
                      fontSize={["12px", "12px", "12px", "12px", "16px"]}
                      justifyContent="space-between"
                      h={["20px", "40px", "60px"]}
                      color={colors.primary}
                    >
                      <Text textAlign="start" w="25%" as="i">
                        {t("table.paymentDate")}
                      </Text>
                      <Text textAlign="center" w="25%" as="i">
                        {t("table.amountOfPayment")}
                      </Text>
                      <Text textAlign="center" w="25%" as="i">
                        {t("table.currency")}
                      </Text>
                      <Text textAlign="center" w="25%" as="i">
                        {t("table.startDatePeriod")}
                      </Text>
                      <Text textAlign="end" w="25%" as="i">
                        {t("table.endDatePeriod")}
                      </Text>
                    </HStack>

                    <List mb={["1rem", "1.5rem", "2rem"]} w="100%">
                      {studentPaymentsStatus == "loading" &&
                        Array.from({ length: 2 }).map(() => {
                          return (
                            <Skeleton>
                              <ListItem
                                cursor="default"
                                fontSize={[
                                  "12px",
                                  "12px",
                                  "8px",
                                  "12px",
                                  "16px",
                                ]}
                                fontWeight="400"
                                w="100%"
                                padding="20px 14px"
                              ></ListItem>
                              <Divider
                                w="100%"
                                mb="16px"
                                borderColor={"gray.300"}
                                borderWidth="1px"
                              />
                            </Skeleton>
                          );
                        })}

                      {studentPaymentsStatus == "success" &&
                        studentPaymentsResp?.data?.map((x) => {
                          return (
                            <>
                              <ListItem
                                cursor="default"
                                fontSize={["12px", "12px", "12px", "16px"]}
                                fontWeight="400"
                                w="100%"
                                padding="4px 14px"
                                px={[
                                  "12px",
                                  "12px",
                                  "8px",
                                  "10px",
                                  "12px",
                                  "16px",
                                ]}
                              >
                                <Flex justifyContent="space-between">
                                  <Text textAlign="start" w="25%">
                                    {format(x.paymentDate, "dd MMM yyyy")}
                                  </Text>
                                  <Text textAlign="center" w="25%">
                                    {x.amount}
                                  </Text>
                                  <Text textAlign="center" w="25%">
                                    {x.currency}
                                  </Text>
                                  <Text textAlign="center" w="25%">
                                    {format(x.periodStartDate, "dd MMM yyyy")}
                                  </Text>
                                  <Text textAlign="end" w="25%">
                                    {format(x.periodEndDate, "dd MMM yyyy")}
                                  </Text>
                                </Flex>
                              </ListItem>
                              <Divider
                                w="100%"
                                mb="16px"
                                borderColor={"gray.300"}
                                borderWidth="1px"
                              />
                            </>
                          );
                        })}
                    </List>
                  </Box>
                </SimpleBar>
              ) : (
                <Box>
                  <HStack
                    m="3.5rem 0 1rem"
                    mt={["1rem", "2rem", "3rem", "3.5rem"]}
                    padding="14px"
                    bg="#EAF5F4"
                    fontSize={["12px", "12px", "12px", "12px", "16px"]}
                    justifyContent="space-between"
                    h={["20px", "40px", "60px"]}
                    color={colors.primary}
                  >
                    <Text textAlign="start" w="25%" as="i">
                      {t("table.paymentDate")}
                    </Text>
                    <Text textAlign="center" w="25%" as="i">
                      {t("table.amountOfPayment")}
                    </Text>
                    <Text textAlign="center" w="25%" as="i">
                      {t("table.currency")}
                    </Text>
                    <Text textAlign="center" w="25%" as="i">
                      {t("table.startDatePeriod")}
                    </Text>
                    <Text textAlign="end" w="25%" as="i">
                      {t("table.endDatePeriod")}
                    </Text>
                  </HStack>

                  <List mb={["1rem", "1.5rem", "2rem"]} w="100%">
                    {studentPaymentsStatus == "loading" &&
                      Array.from({ length: 2 }).map(() => {
                        return (
                          <Skeleton>
                            <ListItem
                              cursor="default"
                              fontSize={["12px", "12px", "8px", "12px", "16px"]}
                              fontWeight="400"
                              w="100%"
                              padding="20px 14px"
                            ></ListItem>
                            <Divider
                              w="100%"
                              mb="16px"
                              borderColor={"gray.300"}
                              borderWidth="1px"
                            />
                          </Skeleton>
                        );
                      })}

                    {studentPaymentsStatus == "success" &&
                      studentPaymentsResp?.data?.map((x) => {
                        return (
                          <>
                            <ListItem
                              cursor="default"
                              fontSize={["12px", "12px", "12px", "16px"]}
                              fontWeight="400"
                              w="100%"
                              padding="4px 14px"
                              px={[
                                "12px",
                                "12px",
                                "8px",
                                "10px",
                                "12px",
                                "16px",
                              ]}
                            >
                              <Flex justifyContent="space-between">
                                <Text textAlign="start" w="25%">
                                  {format(x.paymentDate, "dd MMM yyyy")}
                                </Text>
                                <Text textAlign="center" w="25%">
                                  {x.amount}
                                </Text>
                                <Text textAlign="center" w="25%">
                                  {x.currency}
                                </Text>
                                <Text textAlign="center" w="25%">
                                  {format(x.periodStartDate, "dd MMM yyyy")}
                                </Text>
                                <Text textAlign="end" w="25%">
                                  {format(x.periodEndDate, "dd MMM yyyy")}
                                </Text>
                              </Flex>
                            </ListItem>
                            <Divider
                              w="100%"
                              mb="16px"
                              borderColor={"gray.300"}
                              borderWidth="1px"
                            />
                          </>
                        );
                      })}
                  </List>
                </Box>
              )}
            </Box>
          )}
        {studentPaymentsStatus == "error" && (
          <Box m="28px 0" textAlign="center">
            <Text fontWeight="600" fontSize="24px" color="red">
              {t("errMessages.message1")}{" "}
            </Text>
            <Text fontWeight="600" fontSize="24px" color="red">
              {t("errMessages.message2")}
            </Text>
          </Box>
        )}

        {studentPaymentsStatus === "success" &&
          studentPaymentsResp?.data?.length === 0 && (
            <Box m="28px 0" textAlign="center">
              <Text fontWeight="600" fontSize="24px" color={"gray"}>
                {t("No payment data for this group.")}
              </Text>
            </Box>
          )}

        {groupsResp?.data?.groups?.length == 0 && groupsSatus == "success" && (
          <Box m="28px 0" textAlign="center">
            <Text fontWeight="600" fontSize="24px" color={"gray"}>
              {t("No payment data for this student.")}
            </Text>
          </Box>
        )}
      </Container>
    </Flex>
  );
}
