import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { colors } from "../Constants";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../redux/slices/languageSlice";
import { forgotpassword } from "../services/securityService";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import {
  forgotPasswordSchema,
  getForgotPasswordSchema,
} from "./validations/forgotPasswordSchema";
import useSmartRedirection from "../hooks/useSmartRedirection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEarthAmericas } from "@fortawesome/free-solid-svg-icons";

export default function ForgotPassword() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const language = useSelector((state) => state.language.language);
  // useSmartRedirection();
  const { mutate, isLoading } = useMutation(forgotpassword, {
    onSuccess: () => {
      setIsSubmitted(true);
    },
  });
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation("global");
  const validationSchema = getForgotPasswordSchema(t);
  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    dispatch(setLanguage(lang));
  };

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  const activeStyle = {
    backgroundColor: colors.primary,
    color: "white",
  };

  const formik = useFormik({
    initialValues: {
      username: "",
    },

    validationSchema: validationSchema,

    onSubmit: (values) => {
      mutate(values);
    },
  });

  return (
    <>
      <style>{"body { background-color: #319795}"}</style>

      <Menu>
        <MenuButton
          position="absolute"
          top="12px"
          right="20px"
          bg="white"
          p="6px"
          w="40px"
          borderRadius="4px"
          as={Button}
          sx={{
            "@media (max-width: 900px)": {
              width: "30px",
            },
          }}
        >
          <FontAwesomeIcon
            fontSize="20px"
            color="teal"
            icon={faEarthAmericas}
          />
        </MenuButton>
        <MenuList padding={"10px"}>
          <MenuItem
            style={language === "en" ? activeStyle : {}}
            onClick={() => handleLanguageChange("en")}
            fontWeight="500"
            _hover={{ color: colors.primary }}
          >
            EN
          </MenuItem>
          <MenuItem
            style={language === "ru" ? activeStyle : {}}
            onClick={() => handleLanguageChange("ru")}
            fontWeight="500"
            _hover={{ color: colors.primary }}
          >
            RU
          </MenuItem>
          <MenuItem
            style={language === "aze" ? activeStyle : {}}
            onClick={() => handleLanguageChange("aze")}
            fontWeight="500"
            _hover={{ color: colors.primary }}
          >
            AZE
          </MenuItem>
        </MenuList>
      </Menu>
      <Flex
        height="90vh"
        justifyContent="center"
        alignItems="center"
        backgroundColor={colors.primary}
        sx={{
          "@media (max-width: 900px)": {
            height: "100vh",
          },
        }}
      >
        {!isSubmitted ? (
          <Box
            w="768px"
            bg="white"
            padding="32px 40px"
            borderRadius="28px"
            sx={{
              "@media (max-width: 576px)": {
                padding: "24px 20px",
              },
            }}
          >
            <Text
              color={colors.primary}
              fontSize="36px"
              fontWeight="700"
              textAlign="center"
              pb="28px"
              sx={{
                "@media (max-width: 900px)": {
                  fontSize: "32px",
                },
                "@media (max-width: 576px)": {
                  fontSize: "28px",
                  pb: "12px",
                },
              }}
            >
              Forgot password
            </Text>
            <Flex
              flexDir="column"
              gap="20px"
              as="form"
              border="2px solid "
              borderColor={colors.gray}
              borderRadius="16px"
              padding="24px"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  formik.handleSubmit();
                }
              }}
              sx={{
                "@media (max-width: 576px)": {
                  gap: "8px",
                  padding: "12px",
                },
              }}
            >
              <FormControl>
                <FormLabel
                  ms="4px"
                  fontSize="16px"
                  fontWeight="600"
                  fontFamily="sans-serif"
                  color={colors.primary}
                >
                  {t("table.username")}
                </FormLabel>
                <Input
                  fontSize="16px"
                  type="text"
                  _placeholder={{ color: colors.gray, fontSize: "sm" }}
                  placeholder={t("table.username")}
                  onChange={formik.handleChange}
                  value={formik.values.username}
                  onBlur={formik.handleBlur}
                  name="username"
                  size="lg"
                  border="1px"
                  borderColor={colors.gray}
                  sx={{
                    "@media (max-width: 576px)": {
                      h: "40px",
                    },
                  }}
                />
                {formik.errors.username && formik.touched.username && (
                  <Text p="0" pl="4px" m="0" color="red">
                    {formik.errors.username}
                  </Text>
                )}
              </FormControl>

              <Flex
                justifyContent="end"
                alignItems="center"
                maxW="100%"
                mt="0px"
              >
                <Button
                  variant="link"
                  textDecor="underline"
                  color={colors.primary}
                  fontWeight="500"
                  fontSize="16px"
                  lineHeight="12px"
                ></Button>
              </Flex>
              <Button
                isLoading={isLoading}
                margin="0 auto"
                textAlign="center"
                fontSize="16px"
                fontWeight="600"
                w="30%"
                h="45"
                color="white"
                transition=".6s"
                _hover={{
                  background: colors.hoverPrimaryColor,
                }}
                bg={colors.primary}
                onClick={formik.handleSubmit}
                textTransform="uppercase"
                sx={{
                  "@media (max-width: 576px)": {
                    fontSize: "14px",
                    h: "40px",
                    mt: "12px",
                  },
                }}
              >
                Send
              </Button>
            </Flex>
          </Box>
        ) : (
          <Text
            color={colors.primary}
            fontSize={["20px", "28px", "36px"]}
            fontWeight="700"
            bg="white"
            w={["90%", "80%", "70%", "60%", "50%", "40%"]}
            padding="32px 40px"
            borderRadius="28px"
            vorder
            textAlign="center"
          >
            Check your email
          </Text>
        )}
      </Flex>
    </>
  );
}
