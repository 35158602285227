import {
  Box,
  Button,
  Card,
  CardBody,
  Center,
  Checkbox,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Skeleton,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { colors } from "../Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faEye,
  faEyeSlash,
  faTrash,
  faUserEdit,
} from "@fortawesome/free-solid-svg-icons";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { updateMyAccount, whoAmIPromise } from "../services/securityService";
import { useFormik } from "formik";
import { s3Client } from "../helpers/s3Client";
import ConfirmationModal from "../teacher/modal/ConfirmationModalT";
import useEditAccountSchema, {
  editAccountSchema,
  getEditAccountSchema,
} from "../general/validations/editAccountSchema";
import PageTitle from "../general/components/PageTitle";
import Resizer from "react-image-file-resizer";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

function AccountT() {
  const { t } = useTranslation("global");
  const validationSchema = getEditAccountSchema(t);

  const queryClient = useQueryClient();
  const [profilePictureUrl, setProfilePictureUrl] = useState();
  const [imageLoading, setImageLoading] = useState(false);
  const { token, roleName } = useSelector((x) => x.security) || {};
  const { data: resp, status } = useQuery(["who-am-i"], () =>
    whoAmIPromise(token)
  );
  const { mutate, isLoading: isUpdating } = useMutation(
    (data) => updateMyAccount(token, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["who-am-i"],
        });
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      name: resp?.data?.name,
      surname: resp?.data?.surname,
      username: resp?.data?.username,
      email: resp?.data?.email,
      phone: resp?.data?.phone,
      password: null,
      newProfilePictureKey: null,
      isProfilePictureDeleted: false,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      mutate(values);
    },
  });

  const fileInputRef = useRef(null);
  const toast = useToast();
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file == null) return;
    const fileExt = file.name.split(".").pop();
    const fileMbSize = file.size / 1024 / 1024;
    if (!file.type.match(/image-*/)) return;
    if (fileMbSize > 2) {
      toast({
        position: "top-right",
        title: "Error",
        description: "Image should be less than 2MB!",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    Resizer.imageFileResizer(
      file,
      file.width,
      file.height,
      fileExt,
      30,
      0,
      (reziedFile) => {
        setImageLoading(true);
        s3Client.uploadFile(`${uuidv4()}_${fileExt}`, reziedFile).then((x) => {
          formik.setFieldValue("newProfilePictureKey", x);
          setImageLoading(false);
        });
      },
      "file"
    );
    setProfilePictureUrl(URL.createObjectURL(file));
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const defImg =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmJ_4IkdK5v2qxNiln5-ntq7WQBe03wykLdA&s";
  const profilePictureKey = resp?.data?.profilePictureKey;
  useEffect(() => {
    setProfilePictureUrl(
      profilePictureKey ? s3Client.getUrl(profilePictureKey) : defImg
    );
  }, [profilePictureKey]);

  const handleDeletePictureClick = () => {
    formik.setFieldValue("isProfilePictureDeleted", true);
    formik.setFieldValue("newProfilePictureKey", null);
    setProfilePictureUrl(defImg);
  };

  return (
    <Flex>
      <Box width="25%" display={["none", "none", "block", "block"]}></Box>

      <Container maxW={["100%", "100%", "75%", "75%"]} margin="0rem auto ">
        <PageTitle
          status={"success"}
          title={t("My Profile")}
          icon={faUserEdit}
        />
        <VStack alignItems="start" gap="20px">
          <Card
            w="100%"
            border={`1px solid ${colors.gray}`}
            p={["12px", "12px", "24px", "24px"]}
            borderRadius="9px"
            boxShadow="none"
            direction="row"
            alignItems="center"
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <Skeleton
                borderRadius="50%"
                w={["100px", "120px", "140px", "140px"]}
                isLoaded={status == "success"}
                sx={{
                  "@media (max-width: 376px)": {
                    w: "90px",
                  },
                }}
              >
                <Box position="relative">
                  <Image
                    accept="image/*"
                    id="profileImage"
                    borderRadius="50%"
                    objectFit="cover"
                    h={["80px", "120px", "140px", "140px"]}
                    w={["80px", "120px", "140px", "140px"]}
                    sx={{
                      "@media (max-width: 376px)": {
                        boxSize: "70px",
                      },
                    }}
                    src={profilePictureUrl}
                  />

                  <Center
                    w={["22px", "22px", "32px", "32px"]}
                    h={["22px", "22px", "32px", "32px"]}
                    bg="white"
                    bottom="8px"
                    right={["14px", "0"]}
                    borderRadius="50%"
                    position="absolute"
                    color="teal"
                  >
                    <FontAwesomeIcon
                      icon={faCamera}
                      onClick={handleUploadButtonClick}
                      style={{ cursor: "pointer" }}
                    />
                  </Center>
                </Box>
              </Skeleton>
            </Box>

            <Input
              type="file"
              id="fileInput"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleImageChange}
              accept="image/*"
            />

            <Stack>
              <CardBody
                display="flex"
                alignItems="center"
                flexDir="column"
                sx={{
                  "@media (max-width: 376px)": {
                    p: "0",
                  },
                }}
              >
                <Skeleton isLoaded={status == "success"}>
                  <Heading
                    size="md"
                    sx={{
                      "@media (max-width: 376px)": {
                        fontSize: "16px",
                      },
                    }}
                  >
                    {resp?.data?.name} {resp?.data?.surname}
                  </Heading>
                  <Text color={colors.gray} py="2">
                    {roleName}
                  </Text>
                </Skeleton>
                <Button
                  gap="16px"
                  fontSize={["12px", "14px", "16px", "16px"]}
                  mt="12px"
                  onClick={handleDeletePictureClick}
                  isDisabled={
                    !resp?.data?.profilePictureKey &&
                    !formik.values.newProfilePictureKey
                  }
                  colorScheme="red"
                  sx={{
                    "@media (max-width: 376px)": {
                      w: "100px",
                    },
                  }}
                >
                  {t("Remove photo")}
                </Button>
              </CardBody>
            </Stack>
          </Card>
        </VStack>

        <Flex
          flexDir="column"
          border={`1px solid ${colors.gray}`}
          borderRadius="9px"
          alignItems="center"
          padding="24px"
          m="2rem 0 .5rem"
          gap="16px"
        >
          <Grid
            templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
            gap="18px"
            w="100%"
          >
            <FormControl>
              <FormLabel fontSize="14px" fontWeight="400" color="#000000DE">
                {t("Name")}
              </FormLabel>

              <Skeleton isLoaded={status == "success"}>
                <Input
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="name"
                  h="44px"
                  fontSize={["14px", "14px", "16px"]}
                  padding="16px 12px"
                  type="text"
                  borderRadius="4px"
                  border={`1px solid ${colors.primary}`}
                />
              </Skeleton>
              {formik.errors.name && formik.touched.name && (
                <Text p="0" pl="4px" m="0" color="red">
                  {formik.errors.name}
                </Text>
              )}
            </FormControl>
            <FormControl>
              <FormLabel fontSize="14px" fontWeight="400" color="#000000DE">
                {t("Surname")}
              </FormLabel>
              <Skeleton isLoaded={status == "success"}>
                <Input
                  value={formik.values.surname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="surname"
                  h="44px"
                  fontSize={["14px", "14px", "16px"]}
                  padding="16px 12px"
                  type="text"
                  borderRadius="4px"
                  border={`1px solid ${colors.primary}`}
                />
              </Skeleton>
              {formik.errors.surname && formik.touched.surname && (
                <Text p="0" pl="4px" m="0" color="red">
                  {formik.errors.surname}
                </Text>
              )}
            </FormControl>
            <FormControl>
              <FormLabel fontSize="14px" fontWeight="400" color="#000000DE">
                E-mail
              </FormLabel>
              <Skeleton isLoaded={status == "success"}>
                <Input
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  h="44px"
                  padding="16px 12px"
                  type="email"
                  borderRadius="4px"
                  border={`1px solid ${colors.primary}`}
                  name="email"
                  fontSize={["14px", "14px", "16px"]}
                />
              </Skeleton>
              {formik.errors.email && formik.touched.email && (
                <Text p="0" pl="4px" m="0" color="red">
                  {formik.errors.email}
                </Text>
              )}
            </FormControl>
            <FormControl>
              <FormLabel fontSize="14px" fontWeight="400" color="#000000DE">
                {t("Phone Number")}
              </FormLabel>
              <Skeleton isLoaded={status == "success"}>
                <Input
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="phone"
                  h="44px"
                  padding="16px 12px"
                  type="text"
                  borderRadius="4px"
                  border={`1px solid ${colors.primary}`}
                  fontSize={["14px", "14px", "16px"]}
                />
              </Skeleton>
              {formik.errors.phone && formik.touched.phone && (
                <Text p="0" pl="4px" m="0" color="red">
                  {formik.errors.phone}
                </Text>
              )}
            </FormControl>
            <FormControl>
              <FormLabel fontSize="14px" fontWeight="400" color="#000000DE">
                {t("Password")}
              </FormLabel>
              <FormLabel fontSize="12px" fontWeight="400" color="#fcbe03">
                {t(
                  "To reset your password enter the new password in the field below and press on save."
                )}
              </FormLabel>
              <InputGroup>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="password"
                  value={formik.values.password}
                  sx={{
                    "&[type='password']": {
                      color: show ? undefined : "teal",
                      fontSize: ["24px", "28px", "36px"],
                      fontFamily: "serif",
                    },
                  }}
                  h="44px"
                  fontSize={["14px", "14px", "16px"]}
                  padding="16px 12px"
                  alignContent="center"
                  alignItems="center"
                  type={show ? "text" : "password"}
                  borderRadius="4px"
                  border={`1px solid ${colors.primary}`}
                />

                <InputRightElement top="50%" transform="translate(-50%, -50%);">
                  <FontAwesomeIcon
                    color="teal"
                    onClick={handleClick}
                    icon={show ? faEyeSlash : faEye}
                  />
                </InputRightElement>
              </InputGroup>
              {formik.errors.password && formik.touched.password && (
                <Text
                  p="0"
                  pl="4px"
                  m="0"
                  color="red"
                  fontSize={["14px", "14px", "16px"]}
                >
                  {formik.errors.password}
                </Text>
              )}
            </FormControl>
          </Grid>
          <Button
            w={["40%", "30%", "30%", "20%"]}
            h={["36px", "40px", "48px"]}
            p="0 24px"
            isLoading={isUpdating}
            isDisabled={!formik.dirty || imageLoading}
            borderRadius="6px"
            colorScheme="teal"
            onClick={formik.handleSubmit}
          >
            {t("Save")}
          </Button>
        </Flex>
      </Container>
    </Flex>
  );
}

export default AccountT;
