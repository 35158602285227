import {
  Box,
  Center,
  Container,
  Flex,
  HStack,
  Select,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import React from "react";
import PageTitle from "../general/components/PageTitle";
import {
  faUserCheck,
  faX,
  faXmark,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { colors } from "../Constants";
import SearchBar from "../student/components/SearchBar";
import AttendanceModalT from "./modal/AttendanceModalT";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteLesson,
  getAttendanceForTeacher,
  markAttendance,
} from "../services/attendanceService";
import { useFormik } from "formik";
import ConfirmationModal from "./modal/ConfirmationModalT";
import ProfilePicture from "../general/components/ProfilePicture";
import { useTranslation } from "react-i18next";

function AttendanceT() {
  const { token, selectedGroupId } = useSelector((x) => x.security);
  const queryClient = useQueryClient();

  const { data: resp, status } = useQuery(
    ["teacher-attendance", selectedGroupId, token],
    () => getAttendanceForTeacher(selectedGroupId, token)
  );

  const { mutate } = useMutation(
    ({ attendanceRecordId, data }) =>
      markAttendance(attendanceRecordId, data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["teacher-attendance"] });
      },
    }
  );
  const formik = useFormik({
    initialValues: {
      attendanceType: null,
    },
    onSubmit: (values) => {
      mutate(values);
    },
  });

  const formatDate = (dateString, dateFormat) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return `${t("Invalid Date")}`;
    }
    return format(date, dateFormat);
  };

  const { mutate: deleteMutate, isLoading: isDeleting } = useMutation(
    (lessonId) => deleteLesson(lessonId, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["teacher-attendance"],
        });
      },
    }
  );

  const handleDeleteConfirm = (lessonId) => {
    deleteMutate(lessonId);
  };

  const { t } = useTranslation("global");

  return (
    <Flex>
      <Box display={["none", "none", "block", "block"]} width="25%"></Box>
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <PageTitle
          title={t("Attendance")}
          status={"success"}
          icon={faUserCheck}
        />
        <HStack justifyContent="space-between">
          <Box w="24%"></Box>

          <AttendanceModalT />
        </HStack>

        <Flex pb="16px" mt="20px" justifyContent="space-between">
          <Box
            w={["70%", "50%", "30%", "30%", "25%"]}
            alignItems="start"
            padding="20px 0"
            h="540px"
            // w="25%"

            pr={["4px", "4px", "4px", "12px"]}
            border="2px solid transparent"
            borderRightColor={colors.primary}
          >
            <Flex
              flexDir="column"
              gap={["0px", "10px", "10px", "10px"]}
              mt={["2.9rem", "4.2rem", "4.160rem", "4.160rem", "4.160rem"]}
            >
              {resp?.data?.students?.map((student) => (
                <Skeleton isLoaded={status == "success"}>
                  <Flex gap="4px" h="63px" alignItems="center">
                    <Flex>
                      <ProfilePicture url={student.profilePictureKey} />
                    </Flex>
                    <Flex
                      w="100%"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Text
                        bg="white"
                        color="black"
                        borderRadius="6px"
                        fontSize={[
                          "10px",
                          "12px",
                          "10px",
                          "13px",
                          "14px",
                          "16px",
                        ]}
                        pr="8px"
                        fontWeight="500"
                      >
                        {`${student.name} ${student.surname} (${student.username})`}
                      </Text>

                      <Text
                        fontSize={[
                          "10px",
                          "12px",
                          "10px",
                          "13px",
                          "14px",
                          "16px",
                        ]}
                        fontWeight="500"
                        color="gray"
                        textAlign="end"
                      >
                        {student.attendancePercentage}%
                      </Text>
                    </Flex>
                  </Flex>
                </Skeleton>
              ))}
            </Flex>
          </Box>

          <Flex
            w={["100%", "100%", "70%", "70%", "75%"]}
            // padding="0 8px 0 10px "
            pl="8px"
            gap="12px"
            sx={{
              "::-webkit-scrollbar-thumb": {
                borderRadius: "12px",
                background: colors.primary,
              },
              "::-webkit-scrollbar": {
                height: "8px",
              },
              "::-webkit-scrollbar-track": {
                width: "6px",
              },
              "@media (max-width: 570px)": {
                w: "70% !important",
              },
            }}
            overflowX="auto"
          >
            {resp?.data?.lessons?.map((lesson) => (
              <Flex
                flexDir="column"
                gap="24px"
                minW={["100%", "49%", "49%", "32.2%"]}
                sx={{
                  "@media (max-width: 570px)": {
                    minW: "100%",
                  },
                }}
              >
                <Skeleton isLoaded={status == "success"}>
                  <Flex
                    justifyContent="space-between"
                    sx={{
                      boxShadow: "-8px 7px 6px 0px rgba(169,151,151,0.75)",
                    }}
                    color="white"
                    bg={colors.primary}
                    borderRadius="6px"
                    padding="12px"
                    h={["50px", "63px", "63px", "63px", "63px"]}
                    w="100%"
                    display="flex"
                    alignItems="center"
                  >
                    <Text
                      fontSize={["12px", "18px", "14px", "16px", "20px"]}
                      fontWeight="500"
                    >
                      {formatDate(lesson.lessonDate, "MMM dd, yyyy")}
                    </Text>
                    <ConfirmationModal
                      icon={faXmark}
                      iconFontSize={"20px"}
                      buttonWidth={"0px"}
                      buttonH={"0"}
                      iconColor="#e61919"
                      text=""
                      isLoading={isDeleting}
                      onConfirm={() => handleDeleteConfirm(lesson.lessonId)}
                    />
                  </Flex>
                </Skeleton>

                <Flex flexDir="column" gap="12px">
                  {lesson?.attendanceRecords?.map((record, index) => (
                    <Skeleton isLoaded={status == "success"}>
                      <React.Fragment key={record?.id || index}>
                        {record === null ? (
                          <Center
                            bg="white"
                            color="black"
                            borderRadius="6px"
                            padding="12px"
                            h={["50px", "63px", "63px", "63px", "63px"]}
                            w="100%"
                            border={`1px solid ${colors.primary}`}
                            fontSize="12px"
                            fontWeight="500"
                          >
                            {t("The student was not enrolled in the group")}
                          </Center>
                        ) : (
                          <Select
                            border={`1px solid ${colors.primary}`}
                            // display="flex"
                            // alignItems="center"
                            bg="white"
                            color="black"
                            borderRadius="6px"
                            h={["50px", "63px", "63px", "63px", "63px"]}
                            w="100%"
                            fontSize="16px"
                            fontWeight="500"
                            defaultValue={record.attendanceType ?? "2"}
                            onChange={(e) => {
                              const attendanceType = parseInt(
                                e.target.value,
                                10
                              );
                              const attendanceRecordId = record?.id;
                              mutate({
                                attendanceRecordId,
                                data: { attendanceType },
                              });
                            }}
                          >
                            <option value="2">{t("Not selected")}</option>
                            <option value="0">P</option>
                            <option value="1">A</option>
                          </Select>
                        )}
                      </React.Fragment>
                    </Skeleton>
                  ))}
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Container>
    </Flex>
  );
}

export default AttendanceT;
