import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  HStack,
  List,
  ListItem,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import {
  faClipboardList,
  faCopy,
  faPlus,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import PageTitle from "../general/components/PageTitle";
import SearchBar from "../student/components/SearchBar";
import { colors } from "../Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteGroup,
  getGroupsForManagerPromise,
} from "../services/groupService";
import { useSelector } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "../teacher/modal/ConfirmationModalT";

export default function Groups() {
  const navigate = useNavigate();
  const { token, institutionId } = useSelector((x) => x.security) || {};
  const { data: resp, status } = useQuery(
    ["manager-groups", token, institutionId],
    () => getGroupsForManagerPromise(institutionId, token)
  );
  const { t } = useTranslation("global");

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleSearchChange = async (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    if (term.length > 0) {
      try {
        const filteredaData = resp?.data?.filter((group) =>
          group.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setSearchResults(filteredaData);
        setShowDropdown(true);
      } catch (error) {
        console.error("Error", error);
      }
    } else {
      setShowDropdown(false);
      setSearchResults([]);
    }
  };

  const queryClient = useQueryClient();

  const { mutate: deleteMutate, isLoading: isDeleting } = useMutation(
    (groupId) => deleteGroup(groupId, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["manager-groups"],
        });
      },
    }
  );

  const handleDeleteConfirm = (groupId) => {
    deleteMutate(groupId);
  };
  return (
    <Flex>
      <Box display={["none", "none", "block", "block"]} width="25%"></Box>
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <PageTitle
          status="success"
          title={t("sidebar.groups.groups")}
          icon={faClipboardList}
        />
        <HStack justifyContent="space-between">
          <Box
            w="24%"
            sx={{
              "@media (min-width: 1024px)": {
                w: "240px",
              },
              "@media (max-width: 1024px)": {
                w: "210px",
              },
              "@media (max-width: 768px)": {
                w: "250px",
              },
              "@media (max-width: 425px)": {
                w: "220px",
              },
              "@media (max-width: 375px)": {
                w: "202px",
              },
            }}
          >
            <SearchBar
              onChange={handleSearchChange}
              disabled={
                status === "loading" ||
                status === "error" ||
                (status === "success" && (!resp || resp?.data?.length === 0))
              }
            />
            {showDropdown && (
              <Box
                sx={{
                  "::-webkit-scrollbar-thumb": {
                    borderRadius: "12px",
                    background: colors.primary,
                  },
                  "::-webkit-scrollbar": {
                    width: "10px",
                  },

                  "@media (max-width: 910px)": {
                    w: "40%",
                  },
                  "@media (max-width: 750px)": {
                    w: "40%",
                  },
                  "@media (max-width: 610px)": {
                    w: "40%",
                  },
                  "@media (max-width: 420px)": {
                    w: "60%",
                  },
                }}
                w="30%"
                position="absolute"
                zIndex="1000"
                maxHeight="400px"
                overflowY="auto"
                border="1px solid #ddd"
                borderRadius="4px"
                bg="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                mt="2"
              >
                {searchResults.length === 0 ? (
                  <Text p="4" textAlign="center">
                    {t("No results found for")} "
                    <Text as="span" color="red">
                      {searchTerm}
                    </Text>
                    "
                  </Text>
                ) : (
                  searchResults.map((result) => (
                    <Box
                      key={result.id}
                      p="4"
                      borderBottom="1px solid #eee"
                      cursor="pointer"
                      onClick={() => {
                        setShowDropdown(false);
                        navigate(`/manager/groups/${result.id}`);
                      }}
                    >
                      {result.name}
                    </Box>
                  ))
                )}
              </Box>
            )}
          </Box>
          <Link to={"/manager/groups/add-group"}>
            <Button
              gap="16px"
              _hover={{ bg: "#2a6b68" }}
              color={"white"}
              bg={colors.primary}
              fontSize={["12px", "12px", "12px", "16px"]}
              border="1px solid "
              w={["100px", "130px", "140px", "150px"]}
            >
              <FontAwesomeIcon icon={faPlus} /> <Text>{t("actions.add")}</Text>
            </Button>
          </Link>
        </HStack>

        <HStack
          m="3rem 0 1rem"
          border="1px solid #EAF5F4"
          borderRadius="6px"
          textAlign="center"
          alignItems="center"
          justifyContent="center"
          gap="12px"
          width="180px"
          height="48px"
          fontWeight="500"
          fontSize={["14px", "14px", "16px", "18px"]}
          bg="#EAF5F4"
        >
          <FontAwesomeIcon icon={faCopy} />
          <Text>{t("sidebar.groups.groups")}</Text>
        </HStack>

        {(status == "loading" || resp?.data?.length > 0) && (
          <HStack
            m="2rem 0 1rem"
            padding="14px"
            bg="#EAF5F4"
            fontSize={["12px", "14px", "16px"]}
            justifyContent="space-between"
            h="60px"
            color={colors.primary}
          >
            <Text as="i">{t("table.groups")}</Text>
          </HStack>
        )}
        <List mb="3rem" w="100%">
          {status == "loading" &&
            Array.from({ length: 3 }, () => (
              <Skeleton>
                <ListItem
                  _hover={{ color: "teal.400" }}
                  fontSize="16px"
                  fontWeight="500"
                  w="100%"
                  padding="25px 14px"
                ></ListItem>
                <Divider
                  w="100%"
                  mb="16px"
                  borderColor={"gray.300"}
                  borderWidth="1px"
                />
              </Skeleton>
            ))}

          {status == "success" &&
            resp?.data?.map((x) => (
              <>
                <HStack pr="20px">
                  <ListItem
                    onClick={() => navigate(`/manager/groups/${x.id}`)}
                    _hover={{ color: "teal.400", cursor: "pointer" }}
                    fontSize="16px"
                    fontWeight="500"
                    w="100%"
                    padding="14px 14px"
                  >
                    {x.name}
                  </ListItem>
                  <ConfirmationModal
                    icon={faXmark}
                    iconFontSize={"20px"}
                    buttonWidth={"0px"}
                    buttonH={"0"}
                    iconColor="#e61919"
                    text=""
                    isLoading={isDeleting}
                    onConfirm={() => handleDeleteConfirm(x.id)}
                  />
                </HStack>

                <Divider
                  w="100%"
                  mb="16px"
                  borderColor={"gray.300"}
                  borderWidth="1px"
                />
              </>
            ))}
        </List>
        <Box m="3rem 0" textAlign="center">
          {status === "error" && (
            <>
              <Text fontWeight="600" fontSize="24px" color="red">
                {t("errMessages.message1")}
              </Text>
              <Text fontWeight="600" fontSize="24px" color="red">
                {t("errMessages.message2")}
              </Text>
            </>
          )}

          {status === "success" && resp?.data?.length === 0 && (
            <Text> {t("respDataLength0.group")}</Text>
          )}
        </Box>
      </Container>
    </Flex>
  );
}
