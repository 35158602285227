import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Skeleton,
  Switch,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import PageTitle from "../general/components/PageTitle";
import { faTableList } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import {
  createExamPromise,
  getExamForUpdatePromise,
  updateExamPromise,
} from "../services/examService";
import useMyJoditEditor from "../hooks/useMyJoditEditor";
import { useFormik } from "formik";
import MyJoditEditor from "../helpers/MyJoditEditor";
import { editExamSchema, getEditExamSchema } from "./validations/editExamSchema";
import { useTranslation } from "react-i18next";

function EditExam() {
  const { t } = useTranslation("global");
  const validationSchema = getEditExamSchema(t);

  const { id } = useParams();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { token } = useSelector((x) => x.security) || {};
  const { content, isDirty, onBlur, fileKey, isSaving, initialize } =
    useMyJoditEditor();

  const btnRef = useRef();
  const checkRef = useRef();
  const handleTimerClick = (e) => {
    if (!e.isTrusted) return;
    if (e.target.checked) {
      formik.setFieldValue("hours", formik.values.hours ?? 0);
      formik.setFieldValue("minutes", formik.values.minutes ?? 0);
      formik.setFieldValue("seconds", formik.values.seconds ?? 0);
    } else {
      formik.setFieldValue("hours", null);
      formik.setFieldValue("minutes", null);
      formik.setFieldValue("seconds", null);
    }
    btnRef.current.click();
  };

  const { data: resp, status } = useQuery(
    ["teacher-exam-for-update", id],
    () => getExamForUpdatePromise(id, token),
    {
      onSuccess: (resp) => {
        if (content == null) {
          initialize(resp?.data?.description);
        }
        formik.setFieldValue(
          "hours",
          resp.data.durationSeconds != null
            ? Math.floor(resp.data.durationSeconds / 3600)
            : null
        );
        formik.setFieldValue(
          "minutes",
          resp.data.durationSeconds != null
            ? Math.floor((resp.data.durationSeconds % 3600) / 60)
            : null
        );
        formik.setFieldValue(
          "seconds",
          resp.data.durationSeconds != null
            ? resp.data.durationSeconds % 60
            : null
        );
        if (resp.data.durationSeconds != null) {
          btnRef.current.click();
          checkRef.current.click();
        }
      },
    }
  );

  const { mutate, isLoading } = useMutation(
    (data) => updateExamPromise(id, data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [
            "teacher-exams",
            "teacher-exam",
            "teacher-exam-for-update",
          ],
        });
        navigate(`/teacher/exams/${id}`);
      },
    }
  );

  const formatDateTimeLocal = (dateString) => {
    if (!dateString) {
      return null;
    }
    const date = new Date(dateString);
    const pad = (num) => String(num).padStart(2, "0");
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
      date.getDate()
    )}T${pad(date.getHours())}:${pad(date.getMinutes())}`;
  };

  var formik = useFormik({
    initialValues: {
      hours: undefined,
      minutes: undefined,
      seconds: undefined,
      name: resp?.data?.name,
      deadline: formatDateTimeLocal(resp?.data?.deadline),
      isGraded: resp?.data?.isGraded,
      isVisible: resp?.data?.isVisible,
      gradingType: resp?.data?.gradingType,
      isAllowedForSubmissionView: resp?.data?.isAllowedForSubmissionView,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let durationSeconds = null;
      if (
        values.hours != null &&
        values.minutes != null &&
        values.seconds != null
      ) {
        durationSeconds =
          values.hours * 3600 + values.minutes * 60 + values.seconds;
      }

      const data = {
        ...values,
        deadline:
          values.deadline == null
            ? null
            : new Date(values.deadline).toISOString(),

        description: (values.description = fileKey),
        durationSeconds: durationSeconds,

        gradingType: (values.gradingType = parseInt(values.gradingType)),
      };
      mutate(data);
    },
    enableReinitialize: true,
  });

  const handleDurationChange = (e, unit) => {
    const value = parseInt(e.target.value) || 0;
    formik.setFieldValue(unit, value.toString());
  };


  return (
    <Flex>
      <Box display={["none", "none", "block", "block"]} width="25%"></Box>
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <Box>
          <PageTitle status={"success"} title={t("Exams")} icon={faTableList} />
        </Box>
        <Flex
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          w="100%"
          margin="8px auto"
          p="20px 0"
          gap="24px"
          bg="#EAF5F4"
        >
          <FormControl w={["90%", "80%", "70%", "60%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Exam name")}
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
              <Input
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                bg="white"
                borderRadius="4px"
                p="0 12px"
                type="text"
                placeholder={t("Exam name")}
              />
              {formik.errors.name && formik.touched.name && (
                <Text p="0" pl="4px" m="0" color="red">
                  {formik.errors.name}
                </Text>
              )}
            </Skeleton>
          </FormControl>
          <FormControl w={["90%", "80%", "70%", "60%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Description of the exam")}
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
              <MyJoditEditor
                content={content}
                isSaving={isSaving}
                onBlur={onBlur}
              />
            </Skeleton>
          </FormControl>

          <FormControl w={["90%", "80%", "70%", "60%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              <Accordion w={"100%"} allowToggle>
                <AccordionItem border={"none"}>
                  <Flex>
                    <Box
                      color={"#00000061"}
                      fontWeight="700"
                      fontSize="14px"
                      as="span"
                      flex="1"
                      textAlign="left"
                    >
                      {t("Timer")}
                    </Box>
                    <Checkbox
                      size="lg"
                      ref={checkRef}
                      onChange={handleTimerClick}
                      colorScheme="teal"
                      borderColor={"teal"}
                    >
                      <AccordionButton display={"none"} ref={btnRef} />
                    </Checkbox>
                  </Flex>

                  <AccordionPanel>
                    <FormLabel fontSize="12px" fontWeight="400" color="#fcbe03">
                      {t(
                        "In case all values are 0 - each submission's duration will be considered as due."
                      )}
                    </FormLabel>
                    <InputGroup gap="12px">
                      <FormControl>
                        <FormLabel
                          color={"#00000061"}
                          fontWeight="700"
                          fontSize="12px"
                        >
                          {t("Hours")}
                        </FormLabel>
                        <Input
                          onBlur={formik.handleBlur}
                          name="hours"
                          value={formik.values.hours}
                          onChange={(e) => handleDurationChange(e, "hours")}
                          bg="white"
                          borderRadius="4px"
                          p="0 12px"
                          type="number"
                          placeholder={t("Hours")}
                          _placeholder={{ color: "#666666" }}
                        />
                        {formik.errors.hours && formik.touched.hours && (
                          <Text p="0" pl="4px" m="0" color="red">
                            {formik.errors.hours}
                          </Text>
                        )}
                      </FormControl>
                      <FormControl>
                        <FormLabel
                          color={"#00000061"}
                          fontWeight="700"
                          fontSize="12px"
                        >
                          {t("Minutes")}
                        </FormLabel>
                        <Input
                          onBlur={formik.handleBlur}
                          name="minutes"
                          value={formik.values.minutes}
                          defaultValue={formik.values.minutes}
                          onChange={(e) => handleDurationChange(e, "minutes")}
                          bg="white"
                          borderRadius="4px"
                          p="0 12px"
                          type="number"
                          placeholder={t("Minutes")}
                          _placeholder={{ color: "#666666" }}
                        />
                        {formik.errors.minutes && formik.touched.minutes && (
                          <Text p="0" pl="4px" m="0" color="red">
                            {formik.errors.minutes}
                          </Text>
                        )}
                      </FormControl>
                      <FormControl>
                        <FormLabel
                          color={"#00000061"}
                          fontWeight="700"
                          fontSize="12px"
                        >
                          {t("Seconds")}
                        </FormLabel>
                        <Input
                          name="seconds"
                          onBlur={formik.handleBlur}
                          value={formik.values.seconds}
                          onChange={(e) => handleDurationChange(e, "seconds")}
                          bg="white"
                          borderRadius="4px"
                          p="0 12px"
                          type="number"
                          placeholder={t("Seconds")}
                          _placeholder={{ color: "#666666" }}
                        />
                        {formik.errors.seconds && formik.touched.seconds && (
                          <Text p="0" pl="4px" m="0" color="red">
                            {formik.errors.seconds}
                          </Text>
                        )}
                      </FormControl>
                    </InputGroup>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </FormLabel>
          </FormControl>

          <FormControl w={["90%", "80%", "70%", "60%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Deadline")}
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
              <Input
                value={
                  formik.values.deadline
                  // ? format(formik.values.deadline, "yyyy-MM-dd")
                  // : null
                }
                bg="white"
                color="#666666"
                name="deadline"
                onChange={formik.handleChange}
                borderRadius="4px"
                p="0 12px"
                type="datetime-local"
              />
            </Skeleton>
          </FormControl>

          <FormControl w={["90%", "80%", "70%", "60%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Grading type")}
            </FormLabel>
            <FormLabel fontSize="12px" fontWeight="400" color="#fcbe03">
              {t(
                "Automatic - grades questions automatically; open-ended questions receive full credit if no correct answer is predefined, but if one is set, submissions are evaluated without regard to upper or lower case."
              )}
              <br />
              {t(
                "Manual - enables teachers to individually grade each question."
              )}
              <br />
              {t(
                "In both cases, the teacher can adjust the grade for each question or change the overall exam grade. This setting can be adjusted even after the exam is created."
              )}
            </FormLabel>
            <InputGroup>
              <Skeleton w="100%" isLoaded={status == "success"}>
                <Select
                  isDisabled={!formik.values.isGraded}
                  onChange={formik.handleChange}
                  value={formik.values.gradingType}
                  name="gradingType"
                  defaultValue={0}
                  bg="white"
                >
                  <option defaultChecked value={0}>
                    {t("Automatic")}
                  </option>
                  <option value={1}>{t("Manual")}</option>
                </Select>
              </Skeleton>
            </InputGroup>
          </FormControl>

          <FormControl
            w={["90%", "80%", "70%", "60%", "50%"]}
            display="flex"
            alignItems="center"
          >
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Graded exam")}
              <FormLabel fontSize="12px" fontWeight="400" color="#fcbe03">
                {t(
                  "Exams can be either graded or ungraded. Submissions of only graded assignments can be assessed."
                )}
              </FormLabel>
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
              <Checkbox
                name="isGraded"
                onChange={formik.handleChange}
                isChecked={formik.values.isGraded}
                colorScheme="teal"
                borderColor={"teal"}
                size="lg"
              />
            </Skeleton>
          </FormControl>
          <FormControl
            w={["90%", "80%", "70%", "60%", "50%"]}
            display="flex"
            alignItems="center"
          >
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Allowed for submission view")}
              <FormLabel fontSize="12px" fontWeight="400" color="#fcbe03">
                {t(
                  "If the exam allows submission view, then students can see their submitted questions. Otherwise, they cannot view their submissions."
                )}
              </FormLabel>
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
              <Checkbox
                name="isAllowedForSubmissionView"
                onChange={formik.handleChange}
                isChecked={formik.values.isAllowedForSubmissionView}
                colorScheme="teal"
                borderColor={"teal"}
                size="lg"
              />
            </Skeleton>
          </FormControl>

          <FormControl
            w={["90%", "80%", "70%", "60%", "50%"]}
            display="flex"
            alignItems="center"
          >
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Visible for students")}
              <FormLabel fontSize="12px" fontWeight="400" color="#fcbe03">
                {t(
                  "If this option is checked, students will be able to see the exam on their end. By default, this option is turned off. You can enable it after you have created at least one question. It is recommended to turn it on only once you have finished creating all your exam questions. The exam cannot be made invisible after someone has started it."
                )}
              </FormLabel>
            </FormLabel>

            <Skeleton isLoaded={status == "success"}>
              <Checkbox
                name="isVisible"
                onChange={formik.handleChange}
                isChecked={formik.values.isVisible}
                colorScheme="teal"
                borderColor={"teal"}
                size="lg"
              />
            </Skeleton>
          </FormControl>
          <Button
            isLoading={isLoading}
            isDisabled={(!formik.dirty && !isDirty) || isSaving}
            colorScheme="teal"
            w={["90%", "80%", "70%", "60%", "50%"]}
            h="40px"
            fontWeight="600"
            fontSize="16px"
            onClick={formik.handleSubmit}
          >
            {t("Save")}
          </Button>
        </Flex>
      </Container>
    </Flex>
  );
}

export default EditExam;
