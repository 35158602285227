import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { colors } from "../../Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleLeft,
  faArrowCircleLeft,
  faArrowLeftRotate,
  faChevronLeft,
  faEarthAmericas,
  faLeftLong,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../redux/slices/languageSlice";
import { useNavigate } from "react-router-dom";
import { HamburgerIcon } from "@chakra-ui/icons";

function PageTitle({
  title,
  icon,
  status,
  fontSize = { base: "1.5rem", sm: "2rem", md: "2.25rem" },
}) {
  const { t, i18n } = useTranslation("global");
  const language = useSelector((state) => state.language.language);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [display, setDisplay] = useState("none");

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    dispatch(setLanguage(lang));
  };

  const activeStyle = {
    backgroundColor: colors.primary,
    color: "white",
  };

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <Flex
        paddingTop={"20px"}
        alignItems="center"
        flexDirection={"column"}
        justifyContent="space-between"
      >
        <HStack
          w="100%"
          alignItems={"center"}
          justifyContent={isSmallScreen ? "end" : "space-between"}
        >
          {isSmallScreen && (
            <Button
              left="70px"
              position="absolute"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </Button>
          )}
          {!isSmallScreen && (
            <Button
              // left="70px"
              // position="absolute"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              {t("Back")}
            </Button>
          )}
          <Menu>
            <MenuButton
              bg={"white"}
              border={`2px solid  ${colors.primary} `}
              color={colors.primary}
              p="6px"
              w="44px"
              sx={{
                "@media (max-width: 700px)": {
                  w: "30px !important",
                },
                "@media (max-width: 500px)": {
                  w: "28px",
                },
              }}
              borderRadius="4px"
              as={Button}
              _hover={{ bg: "white", color: "red", borderColor: "red" }}
              _active={{ bg: "white" }}
              _focus={{ bg: "white" }}
            >
              <FontAwesomeIcon fontSize="20px" icon={faEarthAmericas} />
            </MenuButton>
            <MenuList padding={"10px"}>
              <MenuItem
                style={language === "en" ? activeStyle : {}}
                onClick={() => handleLanguageChange("en")}
                fontWeight="500"
                _hover={{ color: colors.primary }}
              >
                EN
              </MenuItem>
              <MenuItem
                style={language === "ru" ? activeStyle : {}}
                onClick={() => handleLanguageChange("ru")}
                fontWeight="500"
                _hover={{ color: colors.primary }}
              >
                RU
              </MenuItem>
              <MenuItem
                style={language === "aze" ? activeStyle : {}}
                onClick={() => handleLanguageChange("aze")}
                fontWeight="500"
                _hover={{ color: colors.primary }}
              >
                AZE
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
        <Skeleton
          p={{
            base: "12px 0 16px",
            sm: "20px 12px",
            md: "0 0 20px",
          }}
          minW="32%"
          isLoaded={status == "success"}
        >
          <Flex
            color={colors.primary}
            justifyContent="stretch"
            alignItems="baseline"
            gap="16px"
            fontSize={{
              base: "16px",
              sm: "20px",
              md: "28px",
            }}
            px="0"
          >
            {" "}
            <Heading
              mt={{ base: "12px" }}
              fontSize={fontSize}
              letterSpacing={["1px", "1px", "2px", "2px"]}
            >
              {title}
            </Heading>
            <FontAwesomeIcon icon={icon} />
          </Flex>
        </Skeleton>
      </Flex>
    </>
  );
}

export default PageTitle;
