import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Switch, Text, useDisclosure } from "@chakra-ui/react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const RemoveModal = ({
    onConfirm,
    isLoading,
    buttonWidth = "120px",
    icon = faTrash,
    isDisabled,
    buttonH,
    iconFontSize,
    iconColor = "white",
    borderRadius,
    text = "Remove",
    fontSize,
    groupStudentId,
  }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [saveHistory, setSaveHistory] = useState(false);
    const { t } = useTranslation("global");
    const handleConfirm = () => {
      onConfirm(groupStudentId, saveHistory);
      onClose();
    };
  
    return (
      <>
        <Button
          isDisabled={isDisabled}
          gap="16px"
          colorScheme="red"
          h={buttonH}
          onClick={onOpen}
          w={buttonWidth}
          borderRadius={borderRadius}
        >
          <FontAwesomeIcon fontSize={iconFontSize} color={iconColor} icon={icon} />
          {text && <Text fontSize={fontSize}>{text}</Text>}
        </Button>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay bg="rgba(0, 0, 0, 0.7)" />
          <ModalContent top="40px">
            <ModalHeader textAlign="center">{t("Are you sure ?")}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box mt={4}>
                <Switch
                  isChecked={saveHistory}
                  onChange={(e) => setSaveHistory(e.target.checked)}
                >
                  {t("Save History")}
                </Switch>
              </Box>
            </ModalBody>
            <ModalFooter margin="0 auto">
              <Button
                border="1px solid rgba(0, 0, 0, 0.06)"
                w="80px"
                variant="ghost"
                onClick={onClose}
              >
                {t("No")}
              </Button>
              <Button
                colorScheme="red"
                onClick={handleConfirm}
                ml={3}
                w="80px"
                isLoading={isLoading}
              >
                {t("Yes")}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  };
  
  export default RemoveModal;