import React from "react";
import { s3Client } from "../../helpers/s3Client";
import { Box, Image } from "@chakra-ui/react";

export default function ProfilePicture({ url, sizePx }) {
  const defImg =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmJ_4IkdK5v2qxNiln5-ntq7WQBe03wykLdA&s";
  return (
    <Box
      border={"2px solid teal"}
      borderRadius={"50%"}
      overflow={"hidden"}
      width={sizePx ? sizePx : ["22px", "34px", "32px", "36px", "40px", "40px"]}
      height={sizePx ? sizePx : ["22px", "34px", "32px", "36px", "40px", "40px"]}
      marginRight={"10px"}
    >
      <Image
             width={sizePx ? sizePx : ["22px", "34px", "32px", "36px", "40px", "40px"]}

        objectFit={"cover"}
        height={sizePx ? sizePx : ["22px", "34px", "32px", "36px", "40px", "40px"]}

        src={url ? s3Client.getUrl(url) : defImg}
        alt=""
      />
    </Box>
  );
}
