import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { colors } from "../Constants";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { signInPromise } from "../services/securityService";
import { setSecurityInfo } from "../redux/slices/securitySlice";
import { useDispatch, useSelector } from "react-redux";
import useSmartRedirection from "../hooks/useSmartRedirection";
import { useTranslation } from "react-i18next";
import { getSignInSchema, signInSchema } from "./validations/signInSchema";
import { faEarthAmericas } from "@fortawesome/free-solid-svg-icons";
import { setLanguage } from "../redux/slices/languageSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function SignIn() {
  const [show, setShow] = React.useState(false);
  const handleClickPassword = () => setShow(!show);
  const dispatch = useDispatch();
  useSmartRedirection();

  const { t, i18n } = useTranslation("global");
  const validationSchema = getSignInSchema(t);

  const language = useSelector((state) => state.language.language);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    dispatch(setLanguage(lang));
  };

  const { mutate, isLoading } = useMutation(signInPromise, {
    onSuccess: (resp) => {
      dispatch(setSecurityInfo(resp.data));
      // todo: create hook that handles everything
    },
  });

  const formik = useFormik({
    initialValues: {
      username: null,
      password: null,
    },
    validationSchema: validationSchema,

    onSubmit: (values) => {
      mutate(values);
    },
  });

  const activeStyle = {
    backgroundColor: colors.primary,
    color: "white",
  };

  return (
    <>
      <style>{"body { background-color: #319795}"}</style>

      <Menu>
        <MenuButton
          position="absolute"
          top="12px"
          right="20px"
          bg="white"
          p="6px"
          w="40px"
          borderRadius="4px"
          as={Button}
          sx={{
            "@media (max-width: 900px)": {
              width: "30px",
            },
          }}
        >
          <FontAwesomeIcon
            fontSize="20px"
            color="teal"
            icon={faEarthAmericas}
          />
        </MenuButton>
        <MenuList padding={"10px"}>
          <MenuItem
            style={language === "en" ? activeStyle : {}}
            onClick={() => handleLanguageChange("en")}
            fontWeight="500"
            _hover={{ color: colors.primary }}
          >
            EN
          </MenuItem>
          <MenuItem
            style={language === "ru" ? activeStyle : {}}
            onClick={() => handleLanguageChange("ru")}
            fontWeight="500"
            _hover={{ color: colors.primary }}
          >
            RU
          </MenuItem>
          <MenuItem
            style={language === "aze" ? activeStyle : {}}
            onClick={() => handleLanguageChange("aze")}
            fontWeight="500"
            _hover={{ color: colors.primary }}
          >
            AZE
          </MenuItem>
        </MenuList>
      </Menu>
      <Flex
        height="90vh"
        justifyContent="center"
        alignItems="center"
        backgroundColor={colors.primary}
        sx={{
          "@media (max-width: 900px)": {
            height: "100vh",
          },
        }}
      >
        <Box
          w="768px"
          // h="68.5vh"
          bg="white"
          padding="32px 40px"
          borderRadius="28px"
          sx={{
            "@media (max-width: 576px)": {
              // width: "90%",
              padding: "24px 20px",
            },
          }}
        >
          <Text
            color={colors.primary}
            fontSize="36px"
            fontWeight="700"
            textAlign="center"
            pb="28px"
            sx={{
              "@media (max-width: 900px)": {
                fontSize: "32px",
              },
              "@media (max-width: 576px)": {
                fontSize: "28px",
                pb: "12px",
              },
            }}
          >
            Log in
          </Text>
          <Flex
            flexDir="column"
            gap="20px"
            as="form"
            border="2px solid "
            borderColor={colors.gray}
            borderRadius="16px"
            padding="24px"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                formik.handleSubmit();
              }
            }}
            sx={{
              "@media (max-width: 576px)": {
                gap: "8px",
                padding: "12px",
              },
            }}
          >
            <FormControl>
              <FormLabel
                ms="4px"
                fontSize="16px"
                fontWeight="600"
                fontFamily="sans-serif"
                color={colors.primary}
              >
                {t("table.username")}
              </FormLabel>
              <Input
                fontSize="16px"
                type="text"
                _placeholder={{ color: colors.gray, fontSize: "sm" }}
                placeholder={t("table.username")}
                onChange={formik.handleChange}
                value={formik.values.username}
                onBlur={formik.handleBlur}
                name="username"
                size="lg"
                border="1px"
                borderColor={colors.gray}
                sx={{
                  "@media (max-width: 576px)": {
                    h: "40px",
                  },
                }}
              />
              {formik.errors.username && formik.touched.username && (
                <Text p="0" pl="4px" m="0" color="red">
                  {formik.errors.username}
                </Text>
              )}
            </FormControl>

            <FormControl>
              <FormLabel
                ms="4px"
                fontSize="16px"
                fontWeight="600"
                fontFamily="sans-serif"
                color={colors.primary}
              >
                {t("table.password")}
              </FormLabel>

              <InputGroup size="md">
                <Input
                  sx={{
                    "@media (max-width: 576px)": {
                      h: "40px",
                    },
                  }}
                  name={"password"}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  onBlur={formik.handleBlur}
                  fontSize="16px"
                  ms="4px"
                  _placeholder={{ color: colors.gray, fontSize: "sm" }}
                  placeholder={t("table.password")}
                  size="lg"
                  border="1px solid"
                  borderColor={colors.gray}
                  type={show ? "text" : "password"}
                />
                <InputRightElement
                  marginTop="5px"
                  sx={{
                    "@media (max-width: 576px)": {
                      marginTop: "0px",
                    },
                  }}
                  mr="12px"
                  width="4.5rem"
                >
                  <Button
                    h="2rem"
                    size="sm"
                    sx={{
                      "@media (max-width: 576px)": {
                        fontSize: "12px",
                        h: "2rem",
                        w: "3.5rem",
                      },
                    }}
                    w="4rem"
                    onClick={handleClickPassword}
                  >
                    {show ? `${t("Hide")}` : `${t("Show")}`}
                  </Button>
                </InputRightElement>
              </InputGroup>
              {formik.errors.password && formik.touched.password && (
                <Text p="0" pl="4px" m="0" color="red">
                  {formik.errors.password}
                </Text>
              )}
            </FormControl>
            <Flex justifyContent="end" alignItems="center" maxW="100%" mt="0px">
              <Button
                variant="link"
                textDecor="underline"
                color={colors.primary}
                fontWeight="500"
                fontSize="16px"
                lineHeight="12px"
              >
                <Link to="/forgot-password">{t("signIn.forgotP")}</Link>
              </Button>
            </Flex>
            <Button
              isLoading={isLoading}
              margin="0 auto"
              textAlign="center"
              fontSize="16px"
              fontWeight="600"
              w="30%"
              h="45"
              color="white"
              transition=".6s"
              _hover={{
                background: colors.hoverPrimaryColor,
              }}
              bg={colors.primary}
              onClick={formik.handleSubmit}
              textTransform="uppercase"
              sx={{
                "@media (max-width: 576px)": {
                  fontSize: "14px",
                  h: "40px",
                  mt: "12px",
                },
              }}
            >
              {t("signIn.signIn")}
            </Button>
          </Flex>
        </Box>
      </Flex>
    </>
  );
}
