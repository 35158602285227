import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Skeleton,
  Switch,
  VStack,
} from "@chakra-ui/react";
import React, { createRef, useEffect, useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import {
  getPaymentReminderPromise,
  updatePaymentReminderPromise,
} from "../../services/studentPaymentService";
import { useFormik } from "formik";
import { format } from "date-fns";
import { colors } from "../../Constants";
import { useTranslation } from "react-i18next";

export default function PaymentReminderCard({
  groupStudentId,
  isReadOnly = false,
}) {
  const { token } = useSelector((x) => x.security) || {};
  const { data: resp, status } = useQuery(
    [`payment-reminder`, token, groupStudentId],
    () => getPaymentReminderPromise(groupStudentId, token),
    { enabled: groupStudentId != null }
  );
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(
    (data) => updatePaymentReminderPromise(resp?.data?.id, data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["payment-reminder"],
        });
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      nextPaymentDate: resp?.data?.nextPaymentDate,
      notificationEnabled: resp?.data?.notificationEnabled,
    },
    onSubmit: (values) => {
      const data = {
        ...values,
        nextPaymentDate: values.nextPaymentDate
          ? new Date(values.nextPaymentDate).toISOString()
          : null,
      };

      mutate(data);
    },
    enableReinitialize: true,
  });

  let ref = useRef();
  useEffect(() => {
    if (formik.values.nextPaymentDate == null) {
      ref.current.value = null;
      return;
    }
    ref.current.value = format(formik.values.nextPaymentDate, "yyyy-MM-dd");
  }, [formik.values.nextPaymentDate]);

  const { t } = useTranslation("global");

  const getStatus = (reminderDate) => {
    if (reminderDate == null) return;
    reminderDate = new Date(reminderDate).setHours(0, 0, 0, 0);
    let today = new Date().setHours(0, 0, 0, 0);
    if (today >= reminderDate) return "Due";

    let dayDiff = Math.floor((reminderDate - today) / (1000 * 60 * 60 * 24));
    if (dayDiff > 0 && dayDiff <= 7) return "Soon";
  };

  return (
    <Card
      width={["100%", "70%", "50%", "35%"]}
      marginTop={["1rem", "2rem"]}
      p={["12px", "20px"]}
      variant={"outline"}
    >
      <Skeleton isLoaded={status == "success"}>
        <VStack gap="16px">
          <FormControl>
            <FormLabel
              color={"#00000061"}
              fontWeight="700"
              fontSize={["10px", "12px", "14px"]}
            >
              {t("payment.paymentDate")} (
              {getStatus(formik.values.nextPaymentDate)})
            </FormLabel>
            <Input
              ref={ref}
              disabled={isReadOnly}
              onChange={(e) => {
                formik.setFieldValue(
                  "nextPaymentDate",
                  e.target.value == "" ? null : e.target.value
                );
              }}
              type="date"
            />
          </FormControl>
          {!isReadOnly && (
            <>
              <FormControl
                justifyContent={"space-between"}
                alignItems={"center"}
                display={"flex"}
              >
                <FormLabel
                  m={0}
                  color={"#00000061"}
                  fontWeight="700"
                  fontSize="14px"
                >
                  {t("payment.reminder")}
                </FormLabel>
                <Checkbox
                  isChecked={formik.values.notificationEnabled}
                  borderColor={"teal"}
                  colorScheme="teal"
                  onChange={(e) => {
                    formik.setFieldValue(
                      "notificationEnabled",
                      e.target.checked
                    );
                  }}
                />
              </FormControl>
              <Button
                color="white"
                isLoading={isLoading}
                bg={colors.primary}
                isDisabled={!formik.dirty}
                onClick={formik.handleSubmit}
                w="100%"
                h="30px"
                fontWeight="600"
                fontSize="16px"
                _hover={{ bg: "#2a6b68" }}
              >
                {t("save")}
              </Button>
            </>
          )}
        </VStack>
      </Skeleton>
    </Card>
  );
}
