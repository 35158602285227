import React, { useRef, useState } from "react";
import PageTitle from "../general/components/PageTitle";
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  Flex,
  HStack,
  List,
  ListItem,
  Skeleton,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { colors } from "../Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboardList,
  faCopy,
  faEdit,
  faPaperclip,
  faPaste,
  faSquareFull,
} from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";

import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteMaterial,
  getMaterialsForTeacher,
} from "../services/materialService";
import { s3Client } from "../helpers/s3Client";
import ConfirmationModal from "./modal/ConfirmationModalT";
import InnerHtml from "../helpers/InnerHtml";
import ProfilePicture from "../general/components/ProfilePicture";
import ReadOnlyFile from "../general/components/ReadOnlyFile";
import { useTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";

function MaterialSubmission() {
  const navigate = useNavigate();
  const { token } = useSelector((x) => x.security);
  const { id } = useParams();

  const { data: resp, status } = useQuery(["teacher-single-material", id], () =>
    getMaterialsForTeacher(id, token)
  );

  const queryClient = useQueryClient();

  const formatDate = (dateString, dateFormat) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return `${t("Invalid Date")}`;
    }
    return format(date, dateFormat);
  };

  const { mutate: deleteMutate, isLoading: isDeleting } = useMutation(
    () => deleteMaterial(id, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["teacher-single-material"],
        });
        navigate("/teacher/materials");
      },
    }
  );

  const handleDeleteConfirm = () => {
    deleteMutate();
  };

  const { t } = useTranslation("global");
  const [isLessThan610px] = useMediaQuery("(max-width: 610px)");

  return (
    <Flex>
      <Box display={["none", "none", "block", "block"]} width="25%"></Box>
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <PageTitle title={resp?.data?.name} status={status} icon={faPaste} />

        <HStack mt="8px" justifyContent="space-between">
          <Skeleton minW="20%" maxW="80%" isLoaded={status == "success"}>
            <Flex
              display={"flex"}
              alignItems={"center"}
              fontSize={["8px", "12px", "14px", "14px", "18px", "22px"]}
              fontWeight="600"
            >
              <ProfilePicture url={resp?.data?.teacherProfilePictureKey} />
              <HStack flexWrap="wrap">
                <Text whiteSpace="nowrap">
                  {`${resp?.data?.teacherName} ${resp?.data?.teacherSurname} (${resp?.data?.teacherUsername})`}
                  {" | "}
                </Text>
                <Text pl="4px" as="span">
                  {`${t("Posted")} - ${format(
                    resp?.data?.createdTime ?? new Date(),
                    "MMM do yyy"
                  )}`}
                </Text>
              </HStack>
            </Flex>
          </Skeleton>

          <Flex
            //  border="1px solid red"
            gap={["10px", "14px", "20px", "20px"]}
          >
            <Button
              onClick={() => navigate(`/teacher/materials/edit-material/${id}`)}
              fontSize={["10px", "10px", "12px", "14px", "16px"]}
              gap="14px"
              alignItems="center"
              _hover={{ bg: "#2a6b68" }}
              color={"white"}
              bg={colors.primary}
              border="1px solid "
              w={["60px", "70px", "80px", "110px", "160px"]}
              h={["24px", "28px", "30px", "40px"]}
            >
              <FontAwesomeIcon icon={faEdit} /> <Text>{t("Edit")}</Text>
            </Button>
            <ConfirmationModal
              buttonWidth={["60px", "70px", "80px", "110px", "160px"]}
              buttonH={["24px", "28px", "30px", "40px"]}
              fontSize={["10px", "10px", "12px", "14px", "16px"]}
              isLoading={isDeleting}
              onConfirm={handleDeleteConfirm}
            />
          </Flex>
        </HStack>
        <Divider mt="20px" borderColor="teal" borderWidth="2px" />
        <Skeleton mb="20px" minH="8vh" isLoaded={status == "success"}>
          <Box m="20px 0" lineHeight="22px">
            <Text
              color="#00000099"
              // w="50%"
            >
              {resp?.data?.description ? (
                <InnerHtml fileKey={resp?.data?.description} />
              ) : (
                <Text
                  fontWeight="600"
                  w="100%"
                  textAlign="center"
                  m="2rem auto"
                >
                  {t("No description")}
                </Text>
              )}
            </Text>
          </Box>

          <Box m="2rem 0 4rem">
            <HStack flexWrap="wrap" gap="24px">
              {resp?.data?.fileAttachments?.map((x, i) => {
                return <ReadOnlyFile key={i} fileKey={x.key} />;
              })}
            </HStack>
          </Box>
        </Skeleton>

        <HStack
          border="1px solid #EAF5F4"
          borderRadius="6px"
          textAlign="center"
          alignItems="center"
          justifyContent="center"
          gap="12px"
          width="163px"
          height="48px"
          fontWeight="500"
          fontSize={["14px", "16px", "18px"]}
          bg="#EAF5F4"
        >
          <FontAwesomeIcon icon={faCopy} />
          <Text>{t("Compliances")}</Text>
        </HStack>

        <Box mb="28px" w="100%">
          {isLessThan610px ? (
            <SimpleBar
              autoHide={false}
              style={{ maxHeight: "calc(100vh - 100px)" }}
            >
              <Box
                sx={{
                  "@media (max-width: 610px)": {
                    minW: "600px",
                  },
                }}
              >
                <HStack
                  m="2rem 0"
                  mt={["1rem", "2rem"]}
                  padding="14px"
                  bg="#EAF5F4"
                  fontSize={["12px", "12px", "12px", "12px", "16px"]}
                  justifyContent="space-between"
                  h={["20px", "40px", "60px"]}
                  color={colors.primary}
                >
                  <Text textAlign="start" w="50%" as="i">
                    {t("Name")}
                  </Text>
                  <Text textAlign="end" w="50%" as="i">
                    {t("Status")}
                  </Text>
                </HStack>

                <List mb={["1rem", "1.5rem", "2rem"]} w="100%">
                  {status === "loading" && (
                    <>
                      {Array.from({ length: 3 })?.map((_, index) => (
                        <Skeleton m="12px 0" key={index}>
                          <ListItem
                            fontSize={["12px", "12px", "8px", "12px", "16px"]}
                            padding="0 14px"
                          >
                            <Flex alignItems="center">
                              <Text
                                fontSize={[
                                  "12px",
                                  "12px",
                                  "8px",
                                  "12px",
                                  "16px",
                                ]}
                                fontWeight="500"
                                w="100%"
                              ></Text>
                              <Button mb="8px" w="180px"></Button>
                            </Flex>
                          </ListItem>
                        </Skeleton>
                      ))}
                    </>
                  )}

                  {resp?.data?.compliances?.map((x) => (
                    <>
                      <ListItem
                        fontSize={["12px", "12px", "12px", "16px"]}
                        fontWeight="400"
                        w="100%"
                        // padding="4px 14px"
                        px={["12px", "12px", "8px", "10px", "12px", "16px"]}
                        key={x.id}
                      >
                        <Flex
                          justifyContent={"space-between"}
                          alignItems="center"
                        >
                          <Text
                            alignItems={"center"}
                            display={"flex"}
                            fontSize={["10px", "12px", "16px"]}
                            fontWeight="500"
                            w="100%"
                          >
                            <Flex>
                              {" "}
                              <ProfilePicture
                                url={x.studentProfilePictureKey}
                              />
                            </Flex>
                            {`${x.studentName} ${x.studentSurname} (${x.studentUsername})`}
                          </Text>
                          <Text textAlign="end" w="50%">
                            {x.complianceTime ? (
                              <Button
                                mb="8px"
                                minW={["140px", "180px", "190px"]}
                                _hover={{ bg: colors.primary }}
                                borderRadius="4px"
                                bg={colors.primary}
                                border="1px solid"
                                padding="6px"
                                color="white"
                                fontSize={["10px", "12px", "16px"]}
                                cursor="default"
                                fontWeight="400"
                              >
                                {formatDate(x.complianceTime, "MMM do, yyy")}
                              </Button>
                            ) : (
                              <Button
                                fontSize={["10px", "12px", "16px"]}
                                mb="8px"
                                minW={["140px", "180px", "190px"]}
                                _hover={{ bg: "white" }}
                                cursor="default"
                                borderRadius="4px"
                                color={colors.primary}
                                border="1px solid"
                                padding="6px"
                                bg="white"
                                fontWeight="400"
                              >
                                {t("Have not read yet")}
                              </Button>
                            )}
                          </Text>
                        </Flex>
                      </ListItem>
                      <Divider
                        w="100%"
                        mb={["8px", "8px", "16px"]}
                        borderColor={colors.gray}
                        borderWidth="1px"
                      />
                    </>
                  ))}
                </List>
              </Box>
            </SimpleBar>
          ) : (
            <Box>
              <HStack
                m="2rem 0"
                mt={["1rem", "2rem"]}
                padding="14px"
                bg="#EAF5F4"
                fontSize={["12px", "12px", "12px", "12px", "16px"]}
                justifyContent="space-between"
                h={["20px", "40px", "60px"]}
                color={colors.primary}
              >
                <Text textAlign="start" w="50%" as="i">
                  {t("Name")}
                </Text>
                <Text textAlign="end" w="50%" as="i">
                  {t("Status")}
                </Text>
              </HStack>

              <List mb={["1rem", "1.5rem", "2rem"]} w="100%">
                {status === "loading" && (
                  <>
                    {Array.from({ length: 3 })?.map((_, index) => (
                      <Skeleton m="12px 0" key={index}>
                        <ListItem
                          fontSize={["12px", "12px", "8px", "12px", "16px"]}
                          padding="0 14px"
                        >
                          <Flex alignItems="center">
                            <Text
                              fontSize={["12px", "12px", "8px", "12px", "16px"]}
                              fontWeight="500"
                              w="100%"
                            ></Text>
                            <Button mb="8px" w="180px"></Button>
                          </Flex>
                        </ListItem>
                      </Skeleton>
                    ))}
                  </>
                )}

                {resp?.data?.compliances?.map((x) => (
                  <>
                    <ListItem
                      fontSize={["12px", "12px", "12px", "16px"]}
                      fontWeight="400"
                      w="100%"
                      // padding="4px 14px"
                      px={["12px", "12px", "8px", "10px", "12px", "16px"]}
                      key={x.id}
                    >
                      <Flex
                        justifyContent={"space-between"}
                        alignItems="center"
                      >
                        <Text
                          alignItems={"center"}
                          display={"flex"}
                          fontSize={["10px", "12px", "16px"]}
                          fontWeight="500"
                          w="100%"
                        >
                          <Flex>
                            {" "}
                            <ProfilePicture url={x.studentProfilePictureKey} />
                          </Flex>
                          {`${x.studentName} ${x.studentSurname} (${x.studentUsername})`}
                        </Text>
                        <Text textAlign="end" w="50%">
                          {x.complianceTime ? (
                            <Button
                              mb="8px"
                              minW={["140px", "180px", "190px"]}
                              _hover={{ bg: colors.primary }}
                              borderRadius="4px"
                              bg={colors.primary}
                              border="1px solid"
                              padding="6px"
                              color="white"
                              fontSize={["10px", "12px", "16px"]}
                              cursor="default"
                              fontWeight="400"
                            >
                              {formatDate(x.complianceTime, "MMM do, yyy")}
                            </Button>
                          ) : (
                            <Button
                              fontSize={["10px", "12px", "16px"]}
                              mb="8px"
                              minW={["140px", "180px", "190px"]}
                              _hover={{ bg: "white" }}
                              cursor="default"
                              borderRadius="4px"
                              color={colors.primary}
                              border="1px solid"
                              padding="6px"
                              bg="white"
                              fontWeight="400"
                            >
                              {t("Have not read yet")}
                            </Button>
                          )}
                        </Text>
                      </Flex>
                    </ListItem>
                    <Divider
                      w="100%"
                      mb={["8px", "8px", "16px"]}
                      borderColor={colors.gray}
                      borderWidth="1px"
                    />
                  </>
                ))}
              </List>
            </Box>
          )}
        </Box>
      </Container>
    </Flex>
  );
}

export default MaterialSubmission;
