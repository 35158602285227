import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
  Skeleton,
  RadioGroup,
  Stack,
  Radio,
} from "@chakra-ui/react";
import React from "react";
import PageTitle from "../general/components/PageTitle";
import {
  faClipboardList,
  faMoneyBillTransfer,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getPayrollForManagerPromise,
  updatePayrollPromise,
} from "../services/payrollService";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import ProfilePicture from "../general/components/ProfilePicture";

function SinglePayrollM() {
  const { id } = useParams();
  const { token } = useSelector((x) => x.security) || {};
  const queryClient = useQueryClient();
  const { data: resp, status } = useQuery(
    ["manager-single-payroll", token, id],
    () => getPayrollForManagerPromise(id, token)
  );
  const { mutate, isLoading } = useMutation(
    (data) => updatePayrollPromise(id, data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["manager-payrolls"],
        });
      },
    }
  );
  const formik = useFormik({
    initialValues: {
      periodStartDate: resp?.data?.periodStartDate,
      periodEndDate: resp?.data?.periodEndDate,
      hoursWorked: resp?.data?.hoursWorked,
      salary: resp?.data?.salary,
      currency: resp?.data?.currency,
      description: resp?.data?.description,
      isAccepted: resp?.data?.isAccepted,
    },
    onSubmit: (values) => {
      mutate(values);
    },
    enableReinitialize: true,
  });

  const [t] = useTranslation("global");

  return (
    <Flex>
      <Box display={["none", "none", "block", "block"]} width="25%"></Box>
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <PageTitle
          status={"success"}
          title={t("sidebar.payroll")}
          icon={faMoneyBillTransfer}
        />
        <HStack alignItems="start" justifyContent="space-between">
          <Box>
            <Skeleton isLoaded={status == "success"}>
              <Text
                alignItems={"center"}
                display={"flex"}
                fontWeight="500"
                fontSize={["12px", "14px", "14px", "18px", "22px"]}
              >
                <ProfilePicture url={resp?.data?.profilePictureKey} />
                {`${resp?.data?.name} ${resp?.data?.surname} (${resp?.data?.username})`}
              </Text>
            </Skeleton>
          </Box>
        </HStack>
        <Divider w="100%" m="1rem 0" borderColor="teal" borderWidth="2px" />

        <Flex
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          w={["95%","95%","90%","80%","70%"]}
          margin="8px auto"
          p="20px 0"
          gap="24px"
          bg="#EAF5F4"
        >
          <FormControl w="90%">
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("table.startDatePeriod")}
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
               <Input  fontSize={["10px", "12px", "12px", "14px", "16px"]}
                name="periodStartDate"
                onChange={formik.handleChange}
                value={
                  formik.values.periodStartDate
                    ? format(formik.values.periodStartDate, "yyyy-MM-dd")
                    : null
                }
                bg="white"
                borderRadius="4px"
                p="0 12px"
                type="date"
                placeholder={t("table.startDatePeriod")}
              />
            </Skeleton>
          </FormControl>
          <FormControl  w="90%" >
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("table.endDatePeriod")}
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
               <Input  fontSize={["10px", "12px", "12px", "14px", "16px"]}
                name="periodEndDate"
                onChange={formik.handleChange}
                value={
                  formik.values.periodEndDate
                    ? format(formik.values.periodEndDate, "yyyy-MM-dd")
                    : null
                }
                bg="white"
                borderRadius="4px"
                p="0 12px"
                type="date"
                placeholder={t("table.endDatePeriod")}
              />
            </Skeleton>
          </FormControl>
          <FormControl  w="90%" >
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("table.workedHours")}
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
               <Input  fontSize={["10px", "12px", "12px", "14px", "16px"]}
                name="hoursWorked"
                value={formik.values.hoursWorked}
                onChange={formik.handleChange}
                bg="white"
                borderRadius="4px"
                p="0 12px"
                type="number"
                placeholder={t("table.workedHours")}
              />
            </Skeleton>
          </FormControl>
          <FormControl  w="90%" >
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("table.salary")}
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
               <Input  fontSize={["10px", "12px", "12px", "14px", "16px"]}
                onChange={formik.handleChange}
                value={formik.values.salary}
                name="salary"
                bg="white"
                borderRadius="4px"
                p="0 12px"
                type="number"
              />
            </Skeleton>
          </FormControl>
          <FormControl  w="90%" >
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("table.currency")}
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
               <Input  fontSize={["10px", "12px", "12px", "14px", "16px"]}
                name="currency"
                value={formik.values.currency}
                onChange={formik.handleChange}
                bg="white"
                borderRadius="4px"
                placeholder={t("Currency")}
                p="0 12px"
              />
            </Skeleton>
          </FormControl>
          <FormControl  w="90%" >
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("table.description")}
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
               <Input  fontSize={["10px", "12px", "12px", "14px", "16px"]}
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                bg="white"
                borderRadius="4px"
                p="0 12px"
                type="text"
                placeholder={t("table.description")}
              />
            </Skeleton>
          </FormControl>
          <FormControl  w="90%" >
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("table.status")}{" "}
            </FormLabel>
            <RadioGroup
              onChange={(x) =>
                formik.setFieldValue(
                  "isAccepted",
                  x == 0 ? null : JSON.parse(x.toLowerCase())
                )
              }
              value={formik.values.isAccepted ?? 0}
            >
              <Stack>
                <Skeleton isLoaded={status == "success"}>
                  <Radio colorScheme={"orange"} value={0}>
                    <Text color={"orange"} fontWeight={"bold"}>
                      {t("payroll.pending")}
                    </Text>
                  </Radio>
                </Skeleton>

                <Skeleton isLoaded={status == "success"}>
                  <Radio colorScheme="red" value={false}>
                    <Text color={"red"} fontWeight={"bold"}>
                      {t("payroll.rejected")}
                    </Text>
                  </Radio>
                </Skeleton>

                <Skeleton isLoaded={status == "success"}>
                  <Radio colorScheme="green" value={true}>
                    <Text color={"green"} fontWeight={"bold"}>
                      {t("payroll.accepted")}
                    </Text>
                  </Radio>
                </Skeleton>
              </Stack>
            </RadioGroup>
          </FormControl>
          <Button
            isDisabled={status != "success" || !formik.dirty}
            colorScheme="teal"
            color="white"
            isLoading={isLoading}
            w={["60%","50%","40%","30%","20%"]}
            onClick={formik.handleSubmit}
            h="40px"
            fontWeight="600"
            fontSize="16px"
          >
            {t("save")}
          </Button>
        </Flex>
      </Container>
    </Flex>
  );
}

export default SinglePayrollM;
