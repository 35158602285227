import React from "react";
import { colors } from "../Constants";
import {
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  RadioGroup,
  Stack,
  Flex,
  Radio,
  Divider,
  Textarea,
} from "@chakra-ui/react";
import InnerHtml from "../helpers/InnerHtml";
import { useTranslation } from "react-i18next";

export default React.memo(function QuestionForSubmission({
  question,
  index,
  handleTextOptionChange,
  handleCheckboxOptionChange,
  handleMultipleChoiceOptionChange,
  optionId,
}) {
  const _render = () => {
    switch (question.questionType) {
      case 2:
        return (
          <Flex
            borderRadius="12px"
            border={`1px solid ${colors.primary}`}
          
            w="100%"
            bg="white"
            gap="12px"
            padding="16px 10px"
          >
            <Box width="10px" h="inherit" bg={colors.primary}></Box>
            <FormControl>
              <FormLabel
                fontWeight="600"
                p="10px 12px"
                fontSize={["12px", "14px", "18px", "18px"]}
                bg={colors.lightGreen}
              >
                {question.isRequired && (
                  <span style={{ color: "red" }}>* </span>
                )}{" "}
                {`${t("Question")} ${index}: `}
                <InnerHtml fileKey={question.description} />
              </FormLabel>
              <Textarea
                onChange={handleTextOptionChange}
                border="none"
                resize={"none"}
                questionId={question.id}
                placeholder={t("Text...")}

                rows="1"
              />
              <Divider borderWidth="1px" />
            </FormControl>
          </Flex>
        );
      case 1:
        return (
          <Flex
            borderRadius="12px"
            border={`1px solid ${colors.primary}`}
     
            w="100%"
            bg="white"
            gap="12px"
            padding="16px 10px"
          >
            <Box width="10px" h="inherit" bg={colors.primary}></Box>
            <FormControl>
              <FormLabel
                fontWeight="600"
                p="10px 12px"
                fontSize={["12px", "14px", "18px", "18px"]}
                bg={colors.lightGreen}
              >
                {question.isRequired && (
                  <span style={{ color: "red" }}>* </span>
                )}{" "}
                {`${t("Question")} ${index}: `}
                <InnerHtml fileKey={question.description} />
              </FormLabel>
              <Stack gap="12px" direction="column">
                {question.options.map((y) => {
                  return (
                    <Checkbox
                      onChange={(e) =>
                        handleCheckboxOptionChange(e, question.id)
                      }
                      value={y.id}
                    >
                      {y.description}
                    </Checkbox>
                  );
                })}
              </Stack>
            </FormControl>
          </Flex>
        );

      case 0:
        return (
          <Flex
            borderRadius="12px"
            border={`1px solid ${colors.primary}`}
            
            w="100%"
            bg="white"
            gap="12px"
            padding="16px 10px"
          >
            <Box width="10px" h="inherit" bg={colors.primary}></Box>
            <FormControl>
              <FormLabel
                fontWeight="600"
                p="10px 12px"
                fontSize={["12px", "14px", "18px", "18px"]}
                bg={colors.lightGreen}
              >
                {question.isRequired && (
                  <span style={{ color: "red" }}>* </span>
                )}
                {`${t("Question")} ${index}: `}
                <InnerHtml fileKey={question.description} />
              </FormLabel>
              <RadioGroup
                value={optionId}
                onChange={(optionId) =>
                  handleMultipleChoiceOptionChange(optionId, question.id)
                }
              >
                <Stack gap="12px" direction="column">
                  {question.options.map((y) => {
                    return <Radio value={y.id}>{y.description}</Radio>;
                  })}
                </Stack>
              </RadioGroup>
            </FormControl>
          </Flex>
        );
        break;

      default:
        return <></>;
    }
  };



  const { t } = useTranslation("global");
  return _render();
});
